import { Component, OnInit, Input, ChangeDetectorRef, HostListener, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { LibraryService } from '../library.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

// Formato de fechas
import * as _moment from 'moment';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatSelect } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
const moment = _moment;
import iconSearch from '@iconify/icons-fa-solid/search';
import iconAdd from '@iconify/icons-fa-solid/plus';
import iconFilter from '@iconify/icons-fa-solid/filter';
import iconCampos from '@iconify/icons-fa-solid/exchange-alt';
import iconAccion from '@iconify/icons-fa-solid/check';
import iconClear from '@iconify/icons-fa-solid/times';
import { SelectionType } from '@swimlane/ngx-datatable';
import { PolizasService } from 'src/app/polizas/polizas.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-busqueda-tabla',
    templateUrl: './busqueda-tabla.component.html',
    styleUrls: ['./busqueda-tabla.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class BusquedaTablaComponent implements OnInit {
    @Input() url: string;
    @Input() paramsEspeciales: string;
    @Input() campoRegistros: string;
    @Input() campos: any[];
    @Input() columnas: any[];
    @Input() filtros: any[];
    @Input() acciones: any[];
    @Input() parser: Function;
    @Input() descargarSolicitud: boolean;
    @Input() parserService: any;
    @Input() UsuarioId: number;
    @Input() estado: string;
    @Input() fechaInicioInput: string;
    @Input() fechaFinInput: string;
    @Input() ignorarRuta: boolean = false;
    @Input() activarResaltado: boolean = false;
    @Input() debeResaltar: Function;
    @Input() botonNuevo: boolean;
    @Input() rutaNuevo: string;
    @Input() routerClick: string;
    @Input() limite: number = 15;
    @Input() incluirHoraEnParams: boolean = false;
    @Input() esconderSiVacio: boolean = false;
    @Input() modoSelect: boolean = false;
    @Input() hoverClass: boolean = false;
    @Input() usarTemplate: boolean = false;
    @Input() forzarResponsive: boolean = false;
    @Input() soloMostrarIconoBusqueda: boolean = false;
    @Input() retornarFila: boolean = false;
    @Input() registrosLocales: any[];
    @Input() posiblesEstados: any[];
    @Input() templateRecord: TemplateRef<any>;
    @Output() aplicarAccion: EventEmitter<any> = new EventEmitter();
    @Output() actualizarData: EventEmitter<any> = new EventEmitter();
    @Output() clickAction: EventEmitter<any> = new EventEmitter();
    @Output() selectUpdate: EventEmitter<any> = new EventEmitter();
    @HostListener('window:resize', ['$event'])

    @ViewChild('selectCampo', { static: false }) selectCampo;
    @ViewChild('selectFiltros', { static: false }) selectFiltros;

    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    campoActual: any;
    categoriaId: number;
    busqueda: string;
    fechaInicio: Date;
    fechaFin: Date;
    fechaInicioParam: string;
    fechaFinParam: string;
    datos: any[];
    accionActual: any;
    paginaActual: number;
    total: number;
    min: number;
    max: number;
    cargando: boolean = false;
    resizing: boolean = false;
    subscriptions: Subscription;
    innerWidth: any;
    orderBy: string;
    direction: string;
    rowClass: string;
    filtrosCampos = {};
    camposFiltros = [];
    selected: any[] = [];
    SelectionType = SelectionType;
    randomId = Math.random().toString(36).substring(7);

    // Íconos
    iconSearch = iconSearch;
    iconAdd = iconAdd;
    iconAccion = iconAccion;
    iconFilter = iconFilter;
    iconCampos = iconCampos;
    iconClear = iconClear;

    constructor(
        private service: LibraryService,
        private servicePoliza: PolizasService,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private libraryService: LibraryService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        // Inicializar búsqueda
        if(this.campos && this.campos.length > 0) {
            this.campoActual = this.campos[0];
        }
        if(this.acciones && this.acciones.length > 0) {
            this.accionActual = this.acciones[0];
        }
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            this.authService.buscadorReload.subscribe(event => {
                this.cargando = true;
                setTimeout(() => {
                    this.reiniciarBusqueda();
                }, 300);
            })
        );
        this.subscriptions.add(
            this.authService.generarParams.subscribe(event => {
                var params = this.calcularParams();
                event(params);
            })
        );
        if(this.routerClick) {
            this.rowClass = 'hoverRow';
        }

        // Aplicar filtros que vienen pre-mostrados
        this.camposFiltros = [];
        for (let el of this.filtros) {
            if(el.mostrar) {
                this.camposFiltros.push(el.campo);
                if(el.tipo == 'select-multiple') this.agregarFiltroSelectMultiple(el, {value: el.valor});
            }
        }

        // Obtener params de historial de navegación
        if(this.router.url) {
            let partes = this.router.url.split('?');
            let params = this.authService.obtenerParamsRutaHistorial(partes[0]);
            if(params) {
                let url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: params}).toString();
                this.location.go(url);
                this.asignarParams(params);
            }
            else {
                this.obtenerParamsDeURL();
            }
        }
    }

    ngOnDestroy(){
        this.subscriptions.unsubscribe();
    }

    public reiniciarBusqueda(){
        this.cambioPagina(1, null);
    }

    triggerBuscar() {
        let busquedaSearch = this.busqueda;
        setTimeout(() => {
            if (
                busquedaSearch == this.busqueda
            ) {
                this.cambioPagina(1, null);
            }
        }, 700);
    }


    async buscar() {
        this.cargando = true;
        // Calcular params
        var params = this.calcularParams();

        // Obtener registros
        var finalURL = this.url + params;
        var res = await this.service.obtenerRegistros(finalURL, this.parser.bind(this.parserService), this.campoRegistros);

        if(!res.error) {
            if(this.registrosLocales) {
                this.datos = this.registrosLocales;
                this.total = this.registrosLocales.length;
            }
            else {
                // Actualizar
                this.datos = res.data.registros;
                this.total = res.data.total;
                this.actualizarData.emit(res.data.originalJSON);
            }
            this.cargando = false;
            this.resizeTabla();
        }
        else {
            this.service.crearNotificacion(res.error.mensajeError, 'danger');
            this.cargando = false;
        }
    }

    resizeTabla() {
        setTimeout(() => {
            let elTabla = document.getElementById('tablaNGX' + this.randomId);
            if(elTabla) {
                elTabla.style.width = '99%';
                // elTabla.style.opacity = '0';
            }
            // this.resizing = true;
            setTimeout(() => {
                let elTabla = document.getElementById('tablaNGX' + this.randomId);
                if(elTabla) {
                    elTabla.style.width = '100%';
                    // elTabla.style.opacity = '1';
                }
                // this.resizing = false;
            }, 300);
        }, 50);
    }

    /**
     * Calcula los params a adjuntar en un URL para hacer una búsqueda
     */
    calcularParams(): string {
        var queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
        var params = '?';

        // Página y límite
        params += '&page=' + this.paginaActual;
        queryParams['page'] = this.paginaActual;
        params += '&limit=' + this.limite;
        queryParams['limit'] = this.limite;

        if(this.orderBy) params += '&orderBy=' + this.orderBy;
        if(this.direction) params += '&direction=' + this.direction;

        // Campos de búsqueda
        queryParams['field'] = null;
        queryParams['value'] = null;
        queryParams['min'] = null;
        queryParams['max'] = null;
        if(this.busqueda) this.busqueda = this.busqueda.trim();
        if(this.campoActual){
            if(this.campoActual.tipo === 'texto') {
                if(this.busqueda) {
                    if(this.busqueda.trim() != '') {
                        params += '&field=' + this.campoActual.campo;
                        queryParams['field'] = this.campoActual.campo;
                        params += '&value=' + this.busqueda;
                        queryParams['value'] = this.busqueda;
                    }
                }
            }
            else if(this.campoActual.tipo === 'categoria') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
                params += '&value=' + this.categoriaId;
                queryParams['value'] = this.categoriaId;
            }
            else if(this.campoActual.tipo === 'rango-cantidades') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
                if(this.min && this.min.toString().trim() != '') {
                    params += '&min=' + this.min;
                    queryParams['min'] = this.min;
                }
                if(this.max && this.max.toString().trim() != '') {
                    params += '&max=' + this.max;
                    queryParams['max'] = this.max;
                }
            }
        }

        // Rango de fechas
        queryParams['start'] = null;
        queryParams['finish'] = null;
        if(this.campoActual) {
            if(this.campoActual.tipo === 'rango-fechas') {
                params += '&field=' + this.campoActual.campo;
                queryParams['field'] = this.campoActual.campo;
                if(this.fechaInicioParam){
                    if(this.incluirHoraEnParams) {
                        params += '&start=' + this.fechaInicioParam + ' 00:00:00';
                    }
                    else {
                        params += '&start=' + this.fechaInicioParam;
                    }
                    queryParams['start'] = this.fechaInicioParam;
                }
                if(this.fechaFinParam){
                    if(this.incluirHoraEnParams) {
                        params += '&finish=' + this.fechaFinParam + ' 23:59:59';
                    }
                    else {
                        params += '&finish=' + this.fechaFinParam;
                    }
                    queryParams['finish'] = this.fechaFinParam;
                }
            }
        }

        if(this.fechaInicioInput) {
            queryParams['start'] = this.fechaInicioInput;
            params += '&start=' + this.fechaInicioInput;
        }
        if(this.fechaFinInput) {
            queryParams['finish'] = this.fechaFinInput;
            params += '&finish=' + this.fechaFinInput;
        }

        // Estado
        if(this.estado) params += '&estado=' + this.estado;

        // Usuario
        if(this.UsuarioId) params += '&UsuarioId=' + this.UsuarioId;

        // Filtros
        if(this.filtrosCampos) {
            const keys = Object.keys(this.filtrosCampos);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                var valor = this.filtrosCampos[key];
                if(valor && key) {
                    params += '&' + key + '=' + valor;
                    queryParams[key] = valor;
                }
            }
        }

        // Parámetros especiales
        if(this.paramsEspeciales) params += this.paramsEspeciales;

        // Actualizar params en URL
        if(!this.ignorarRuta){
            var url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: queryParams}).toString();
            this.authService.agregarHistorialBuscador(this.router.url, queryParams);
            this.location.go(url);
        }

        if(!this.ignorarRuta) {
            this.authService.updateParams.next(params);
        }

        return params;
    }

    /**
     * Obtiene los params de la URL actual, actualiza los params del buscador y busca
     */
    obtenerParamsDeURL() {
        this.route.queryParams.subscribe((params: Params) => {
            this.asignarParams(params);
        });
    }

    asignarParams(params: Params) {
        // Número de página
        if(params['page']) this.paginaActual = parseInt(params['page']);
        else this.paginaActual = 1;
        if(params['limit']) this.limite = parseInt(params['limit']);
        else this.limite = 15;

        // Campo de búsqueda
        var campo = params['field'];
        if(campo) {
            // Buscar el campo correcto
            for (let i = 0; i < this.campos.length; i++) {
                const element = this.campos[i];
                if(campo === element.campo) {
                    this.campoActual = element;
                    i = this.campos.length;
                }
            }

            // Asignar valor
            switch(this.campoActual.tipo) {
                case 'texto': {
                    this.busqueda = params['value'];
                    break;
                }
                case 'rango-cantidades': {
                    this.min = parseFloat(params['min']);
                    this.max = parseFloat(params['max']);
                    break;
                }
                case 'categoria': {
                    if(this.campoActual.idNoNumerico) this.categoriaId = params['value'];
                    else this.categoriaId = parseInt(params['value']);
                    break;
                }
                case 'rango-fechas': {
                    this.fechaInicioParam = params['start'];
                    this.fechaFinParam = params['finish'];
                    this.fechaInicio = this.service.convertirFecha(this.fechaInicioParam, 'YYYY-MM-DD', 'date');
                    this.fechaFin = this.service.convertirFecha(this.fechaFinParam, 'YYYY-MM-DD', 'date');
                    break
                }
            }
        }

        for (let filtro of this.filtros) {
            switch(filtro.tipo) {
                case 'texto': {
                    if(params[filtro.campo]) {
                        filtro.valor = params[filtro.campo];
                        this.filtrosCampos[filtro.campo] = params[filtro.campo];
                    }
                    break;
                }
                case 'select-multiple': {
                    if(params[filtro.campo]) {
                        filtro.valor = params[filtro.campo].toString().split(',');
                        this.filtrosCampos[filtro.campo] = params[filtro.campo].toString().split(',');
                    }
                    break;
                }
                case 'select-unico': {
                    if(params[filtro.campo]) {
                        filtro.valor = params[filtro.campo];
                        this.filtrosCampos[filtro.campo] = params[filtro.campo];
                    }
                    break;
                }
            }
        }
        this.changeDetector.markForCheck();
        this.buscar();
    }

    changing($event) {
        this.categoriaId = $event;
    }

    /**
     * Cambia de página y busca
     * @param pagina Número de página al cual dirigirse
     */
    cambioPagina(pagina, $event) {
        this.paginaActual = pagina;
        if($event && $event.pageSize) this.limite = $event.pageSize;
        this.buscar();
    }

    actualizarFechaInicio($event){
        if($event.value && $event.value._d) {
            this.fechaInicioParam = this.service.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.fechaInicioParam = null;
            this.fechaInicio = null;
        }
    }

    actualizarFechaFin($event){
        if($event.value && $event.value._d) {
            this.fechaFinParam = this.service.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.fechaFinParam = null;
            this.fechaFin = null;
        }
    }

    onSort(event) {
        if (event && event.column) {
            this.orderBy = event.column.prop;
            //this.direction = event.newValue;
            if(this.direction == 'asc') this.direction = 'desc';
            else this.direction = 'asc';
        }
        else {
            this.orderBy = null;
            this.direction = null;
        }
        this.cambioPagina(1, null);
    }

    getRowClass(row) {
        return {
            'hoverRow': this.routerClick != null || this.hoverClass,
            'filaResaltada': this.activarResaltado && this.debeResaltar(row),
        };
    }

    /**
     * Inicializa el campo actual después de seleccionarlo
     */
    inicializarCampoActual(){
        if(this.campoActual.tipo === 'categoria'){
            if(this.campoActual.categorias && this.campoActual.categorias.length > 0){
                this.categoriaId = this.campoActual.categorias[0][this.campoActual.categoriaId];
            }
        }
    }

    modoResponsive() {
        if(this.forzarResponsive) return true;
        if(!this.innerWidth) return false;
        return this.innerWidth <= 768;
    }

    limpiarBuscador() {
        this.authService.limpiarBuscador.next();
        this.busqueda = null;
        this.fechaInicioParam = null;
        this.fechaFinParam = null;
        this.fechaInicio = null;
        this.fechaFin = null;
        this.fechaInicioInput = null;
        this.fechaFinInput = null;
        this.categoriaId = null;

        if(this.filtros) {
            for (let filtro of this.filtros) {
                if(!filtro.ignorarLimpieza) {
                    filtro.valor = null;
                    let campo = filtro.campo;
                    this.filtrosCampos[campo] = null;
                    filtro.seleccionados = [];
                }
            }
        }

        this.reiniciarBusqueda();
    }

    onActivate($event) {
        if($event && $event.type == 'click' && !$event.column.checkboxable) {
            if($event.column && $event.column.blockClick == true) return;
            if(this.routerClick) {
                var row = $event.row;
                this.router.navigate([ this.routerClick + row['id'] ]);
            }
            else {
                var row = $event.row;
                if(this.retornarFila)   this.clickAction.emit(row);
                else                    this.clickAction.emit(row['id']);
            }
            if (this.descargarSolicitud) {
                this.solicitud(row['id']);
            }
        }
    }

    async solicitud(idSolicitud) {
        this.cargando = true;
        if (idSolicitud) {
            var res = await this.servicePoliza.descargarPDFSolicitudPolizaCreada(idSolicitud);

            if (!res.error) {
              this.libraryService.crearNotificacion(res.data.mensaje, 'success');
          } else {
              this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
          }
        }
        this.cargando = false;
    }

    // * * * Filtros * * *
    actualizarCamposFiltros($event) {
        var values = $event.value;
        for (let i = 0; i < this.filtros.length; i++) {
            if(values.indexOf(this.filtros[i].campo) != -1) {
                this.filtros[i].mostrar = true;
            }
            else {
                this.filtros[i].mostrar = false;
                this.filtros[i].valor = null;
                this.filtrosCampos[this.filtros[i].campo] = null;
            }
        }
    }

    agregarFiltroTexto(filtro) {
        var campo = filtro.campo;
        this.filtrosCampos[campo] = filtro.valor;
    }

    // Agrega el elemento seleccionado a los campos de filtro
    agregarFiltroAutocompleteUnico(filtro, $event) {
        var campo = filtro.campo;
        if(!$event || $event.length == 0) {
            // Si no seleccionó nada, borrar el campo
            this.filtrosCampos[campo] = null;
        }
        else {
            this.filtrosCampos[campo] = $event[0][filtro.idProp];
        }
    }

    // Agrega el elemento seleccionado a los campos de filtro
    agregarFiltroSelectUnico(filtro, $event) {
        var campo = filtro.campo;
        this.filtrosCampos[campo] = $event.value;
    }

    agregarFiltroSelectMultiple(filtro, $event) {
        var campo = filtro.campo;
        if($event && $event.value) this.filtrosCampos[campo] = $event.value.toString();
    }

    // * * * Acciones * * *
    enviarAccion() {
        if(this.selected && this.selected.length > 0 && this.accionActual) {
            // Validar máximo
            if(this.accionActual.maximo && this.selected.length > this.accionActual.maximo) {
                this.libraryService.crearNotificacionGrande('No puedes seleccionar más de ' + this.accionActual.maximo + ' registros para esta acción.', 'danger');
                return;
            }


            var ids = [];
            for (let i = 0; i < this.selected.length; i++) {
                ids.push(this.selected[i]['id']);
            }
            this.aplicarAccion.emit({ accion: this.accionActual, seleccionados: this.selected, ids: ids });
            this.selected = [];
        }
    }

    onSelect({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        this.selectUpdate.emit(this.selected);
    }

    abrirSelectCampo() {
        if(this.selectCampo) this.selectCampo.open();
    }

    abrirSelectFiltros() {
        if(this.selectFiltros) this.selectFiltros.open();
    }
}
