import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Area } from '../models/area';
import { OperacionAcceso } from '../models/operacionAcceso';

@Injectable()
export class AreasService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * * * Obtener datos * * * * * 
    // Obtener área con su información
    async obtenerAreaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.areaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Editar datos * * * * *
    // Crear un nuevo cliente
    public async crearArea(registro: Area) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Editar cliente existente
    public async actualizarArea(registro: Area) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Eliminar un área
    public async eliminarArea(AreaId: number, NuevoAreaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasURL + '/eliminar';
            const res = await this.connection.postRequest(url, { AreaId: AreaId, NuevoAreaId: NuevoAreaId });
            return { error: null, data: { mensaje: 'Área eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Operaciones de acceso * * * * *
    async obtenerOperacionAccesoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.operacionAccesoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearOperacionAcceso(registro: OperacionAcceso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarOperacionAcceso(registro: OperacionAcceso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarOperacionAcceso(OperacionAccesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL + '/id/' + OperacionAccesoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Operación de acceso eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearOperacionAccesoUsuario(OperacionAccesoId: number, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL + '/guardar-usuario';
            const res = await this.connection.postRequest(url, { OperacionAccesoId: OperacionAccesoId, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Registro guardado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarOperacionAccesoUsuario(OperacionAccesoId: number, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL + '/eliminar-usuario';
            const res = await this.connection.postRequest(url, { OperacionAccesoId: OperacionAccesoId, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Razones pérdidas
    async obtenerTodasOperacionesAcceso() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.operacionAccesoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerOperacionesAccesoUsuario(UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.operacionesAccesoURL + '/usuario/' + UsuarioId;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.operaciones.length; i++) {
                const element = json.operaciones[i];
                registros.push(this.jsonConverters.operacionAccesoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}