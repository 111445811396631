import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { SolicitudPoliza } from 'src/app/models/solicitudPoliza';
import { MapaProceso } from 'src/app/models/mapaProceso';
import { PolizasService } from '../polizas.service';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import { Certificado } from 'src/app/models/certificado';
import { CertificadoSolicitud } from 'src/app/models/certificadoSolicitud';
import { Router } from '@angular/router';
import { PlantillaSolicitud } from 'src/app/models/plantillaSolicitud';
import { SolicitudesService } from 'src/app/solicitudes/solicitudes.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { PlantillaMapaProceso } from 'src/app/models/plantillaMapaProceso';
import { MapasProcesosService } from 'src/app/mapas-procesos/mapas-procesos.service';
import { DialogNuevaMensajeria } from '../dialog-nueva-mensajeria/dialog-nueva-mensajeria.component';
import { DialogPreviewDownloadDocumentComponent } from 'src/app/library/dialog-preview-download-document/dialog-preview-download-document.component';
import { DialogAgregarDocumentoPolizaComponent } from '../dialog-agregar-documento-poliza/dialog-agregar-documento-poliza.component';
import { DocumentoPoliza } from 'src/app/models/documentoPoliza';
import { DialogAgregarMultiplesDocumentosPolizaComponent } from '../dialog-agregar-multiples-documentos/dialog-agregar-multiples-documentos.component';
import { DialogEnviarSolicitudComponent } from '../dialog-enviar-solicitud/dialog-enviar-solicitud.component';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { DialogAgregarDocumentosExpedienteComponent } from '../dialog-agregar-documentos-expediente/dialog-agregar-documentos-expediente.component';
import { DocumentoReclamo } from 'src/app/models/documentoReclamo';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    solicitud: SolicitudPoliza,
    tipoUso: string,
    modulo: string,
}

@Component({
    selector: 'dialog-nueva-solicitud',
    templateUrl: './dialog-nueva-solicitud.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevaSolicitud {
    apiURLs = new APIUrls();
    plantillas: PlantillaSolicitud[] = [];
    cargando: boolean;
    modoCreacion: boolean = true;
    modoEdicion: boolean = false;
    modoDetalle: boolean = false;
    solicitud: SolicitudPoliza;
    jsonConverters: JSONConverters = new JSONConverters();
    titulo: string = 'Solicitud';
    plantillasMapas: PlantillaMapaProceso[];
    plantillasMapasFiltradas: PlantillaMapaProceso[];
    polizaActual: any;
    contactosDeAseguradora: any[] = [];

    iconDelete = iconDelete;
    iconDownload = iconDownload;
    iconUpload = iconUpload;

    // Certificados
    CertificadoIdTemp: number;
    certificadosSolicitud: Certificado[] = [];
    certificadosPoliza: Certificado[] = [];
    certificadosPolizaFiltrado: Certificado[];
    urlAutocompleteCertificados = this.apiURLs.baseURL + this.apiURLs.certificadosURL + '/search';
    certificadosSearch: Certificado[] = [];

    // * * * Documentos * * *
    columnasDocumentos = [
        { prop: 'nombre',       name: 'Nombre'          , sortable: true, cellTemplate: null },
        { prop: 'descripcion',  name: 'Descripcion'     , sortable: true, cellTemplate: null },
        { prop: 'link',         name: 'Descarga'        , sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposDocumentos = [
        { nombre: 'Nombre'      , campo: 'nombre'       , tipo: 'texto', categorias: null },
        { nombre: 'Descripcion' , campo: 'descripcion'  , tipo: 'texto', categorias: null },
    ];
    filtrosDocumentos = [];
    urlBusquedaDocumentos = this.apiURLs.baseURL + this.apiURLs.documentosPolizasURL + '/search';

    // * * * Envíos * * *
    columnasEnvios = [
        { prop: 'destinatarios', name: 'Destinatarios', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'asunto', name: 'Asunto', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposEnvios = [
        { nombre: 'Destinatarios', campo: 'destinatarios', tipo: 'texto', categorias: null },
    ];
    filtrosEnvios = [];
    urlBusquedaEnvios = this.apiURLs.baseURL + this.apiURLs.enviosSolicitudesURL + '/search';

    @ViewChild('cellMonto', { static: true }) cellMonto: TemplateRef<any>;
    @ViewChild('cellDescargarDocumento', { static: true }) cellDescargarDocumento: TemplateRef<any>;

    columnasCertificados = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'nombresPagadores', name: 'Pagadores', sortable: true, cellTemplate: null },
        { prop: 'tipoCertificado.nombre', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'sumaAsegurada', name: 'Suma asegurada', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: true, cellTemplate: null },
    ];
    camposCertificados = [
        { nombre: 'Número', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'General', campo: 'nombre', tipo: 'texto', categorias: null },
    ];

    // Mensajería
    columnasMensajeria = [
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'nombreTipo', name: 'Tipo de mensaje', sortable: true, cellTemplate: null },
        { prop: 'meta.origenDestino', name: 'Origen o destino', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioEjecutivo', name: 'Ejecutivo', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioMensajeria', name: 'Recepción', sortable: true, cellTemplate: null },
    ];
    camposMensajeria = [
        { nombre: 'Enviado por', campo: 'emisor', tipo: 'texto', categorias: null },
        { nombre: 'ID', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'Descripción', campo: 'emisor', tipo: 'texto', categorias: null },
    ];
    urlBusquedaMensajeria = this.apiURLs.baseURL + this.apiURLs.mensajeriaURL + '/search';

    constructor(
        public dialogRef: MatDialogRef<DialogNuevaSolicitud>,
        public servicePoliza: PolizasService,
        public solicitudesService: SolicitudesService,
        public mapasProcesosService: MapasProcesosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }

    ngOnInit() {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.solicitud = this.data.solicitud;
        this.initializeData();
    }

    async initializeData() {
        this.modoCreacion = this.solicitud.id == null || this.solicitud.id == -1;
        this.modoDetalle = !this.modoCreacion;
        this.modoEdicion = false;
        if(this.modoCreacion) this.titulo = 'Nueva solicitud';
        else if(this.modoEdicion) this.titulo = 'Edición de solicitud #' + this.solicitud.numero;
        else if(this.modoDetalle) this.titulo = 'Detalle de solicitud #' + this.solicitud.numero;
        if(this.modoCreacion) {
            this.solicitud.fechaDate = new Date();
            this.actualizarFecha({ value: { _d: this.solicitud.fechaDate } });
            this.solicitud.de = this.auth.usuario.nombreCompleto;
        } else { // Obtener poliza
        }
        await this.obtenerPoliza();
        await this.obtenerPlantillasMapas();
        await this.obtenerCertificadosPolizaDefault();
        this.agregarTemplates();
        this.obtenerPlantillasSolicitudes();
    }

    agregarTemplates() {
        this.columnasCertificados[5].cellTemplate = this.cellMonto;

        this.columnasDocumentos[this.columnasDocumentos.length - 1].cellTemplate = this.cellDescargarDocumento;
    }

    async obtenerPoliza() {
        this.cargando = true;
        let PolizaId = this.solicitud.PolizaId;
        if(!PolizaId && this.solicitud.reclamo) PolizaId = this.solicitud.reclamo.PolizaId;
        var res = await this.servicePoliza.obtenerPolizaPorId(PolizaId);
        if(!res.error) {
            this.polizaActual = res.data.registro;

            // Obtener contactos de aseguradora
            this.contactosDeAseguradora = this.polizaActual.aseguradora.contactos;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerCertificadosPolizaDefault() {
        this.cargando = true;
        if(this.solicitud.PolizaId) {
            var res = await this.servicePoliza.obtenerTodosCertificadosPoliza(this.solicitud.PolizaId);
            if(!res.error) {
                this.certificadosPoliza = res.data.registros;
                this.certificadosPolizaFiltrado = res.data.registros;
                // this.seleccionarTodosCertificados();
                this.actualizarPlantilla();
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async obtenerPlantillasMapas() {
        this.cargando = true;
        var res = await this.mapasProcesosService.obtenerTodasPlantillasRamo();
        if(!res.error) {
            this.plantillasMapas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerPlantillasSolicitudes() {
        this.cargando = true;
        var res = await this.solicitudesService.obtenerTodasPlantillasSolicitudes(this.data.tipoUso, this.data.modulo);
        if (!res.error) {
            let plantillasOriginales: PlantillaSolicitud[] = res.data.registros;
            this.plantillas = [];
            for (const el of plantillasOriginales) {
                let puedeAgregar = false;
                if(!el.idsRamosArray || el.idsRamosArray.length == 0) {
                    puedeAgregar = true;
                }
                else {
                    if(el.idsRamosArray.includes(this.polizaActual.RamoId)) {
                        puedeAgregar = true;
                    }
                }

                if(el.soloReclamosPagados && this.solicitud.ReclamoId && this.solicitud.reclamo && !['abono-a-deducible', 'pagado'].includes(this.solicitud.reclamo.estado)) {
                    puedeAgregar = false;
                }

                if(puedeAgregar) this.plantillas.push(el);
            }

        } else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * Index de certificados * * *
    eliminarCertificado(id: number) {
        if(!this.solicitud.idsCertificados) this.solicitud.idsCertificados = [];
        var index = this.solicitud.idsCertificados.indexOf(id);
        if(index != -1) this.solicitud.idsCertificados.splice(index, 1);
        this.actualizarCertificadosSeleccionados();
    }

    actualizarCertificados(items: Certificado[]) {
        this.solicitud.relacionesCertificado = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            var nuevaRelacion = new CertificadoSolicitud(null, null, null, this.solicitud.id, item.id, this.auth.idEmpresaActual());
            nuevaRelacion.certificado = item;
            this.solicitud.relacionesCertificado.push(nuevaRelacion);
        }
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        //this.solicitud.fechaDate = moment().toDate();
        //this.solicitud.fecha = this.libraryService.convertirFecha(this.solicitud.fechaDate, 'date', 'YYYY-MM-DD');
        this.dialogRef.close(this.solicitud);
    }

    async guardar() {
        this.cargando = true;
        if (this.solicitud) {
            // Arreglar ids declaraciones
            if(this.solicitud.relacionesCertificado) {
                for (let el of this.solicitud.relacionesCertificado) {
                    if(el.idsDeclaracionesArray) el.idsDeclaraciones = el.idsDeclaracionesArray.toString();
                }
            }

            if(this.solicitud.filtrarAclaraciones) {
                this.solicitud.idsAclaraciones = this.solicitud.idsAclaracionesArray.toString();
            }
            else {
                this.solicitud.idsAclaraciones = null;
            }

            // Enviar el registro para guardar
            let res = null;
            if(this.modoCreacion) res = await this.servicePoliza.crearSolicitudPoliza(this.solicitud.PolizaId, this.solicitud);
            else if(this.modoEdicion) res = await this.servicePoliza.actualizarSolicitudPoliza(this.solicitud);

            if (!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
                this.cargando = false;
                if(this.modoCreacion) {
                    this.solicitud.id = res.data.result.solicitud.id;
                    this.solicitud.MapaProcesoId = res.data.result.solicitud.MapaProcesoId;
                    this.modoCreacion = false;
                    this.modoDetalle = true;
                    //this.descargarSolicitud();
                    this.initializeData();
                }
            } else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                this.cargando = false;
            }
        }
        else this.cargando = false;
    }

    cambiarModoEdicion() {
        this.modoCreacion = false;
        this.modoDetalle = false;
        this.modoEdicion = true;
        this.titulo = 'Edición de solicitud';
    }

    registroValido(): boolean {
        var solicitud = this.solicitud;
        if (!solicitud) return false;

        if(this.modoCreacion && !this.solicitud.PlantillaMapaProcesoId && this.plantillasMapasFiltradas && this.plantillasMapasFiltradas.length > 0) return false;

        return true;
    }

    irAMapa() {
        if(!this.modoCreacion) {
            this.router.navigate(['/mapas-procesos/detalle-mapa/' + this.solicitud.MapaProcesoId]);
            this.cerrar();
        }
    }

    abrirNuevaMensajeria() {
        const dialogRef = this.dialog.open(DialogNuevaMensajeria, {
            data: {
                SolicitudPolizaId: this.solicitud.id,
                PolizaId: this.solicitud.PolizaId,
                MensajeriaId: null,
                tipoCreacion: 'envio',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.auth.buscadorReload.next();
            }
        });
    }

    clickMensajeria($event) {
        this.router.navigate(['/mensajeria/detalle/' + $event.id]);
        this.cerrar();
    }

    async descargarSolicitud() {
        try {
            this.cargando = true;
            if (this.solicitud.id) {
                if(this.solicitud.urlVieja) {
                    window.open(this.solicitud.urlVieja, '_blank');
                }
                else {
                    const nombreArchivo = `Solicitud #${this.solicitud.id} - ${this.solicitud.plantilla.nombre}`;
                    var res = await this.servicePoliza.descargarPdfSolicitud(this.solicitud.id, nombreArchivo);
                    if (!res.error) {
                        this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                    } else {
                        this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                    }

                }
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
        }
    }

    async visualizarSolicitudPDF(){
        this.cargando = true;
        var res: any = await this.servicePoliza.visualizarPolizaPDF(this.solicitud.id);
        if (res.error) {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            return;
        }
        this.cargando = false;
        if(res.data.descargaInmediata) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
                this.abrirPreviewDocumento(pdfSrc)
            };
            await fileReader.readAsArrayBuffer(res.data.blob);
        }
        else {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
    }

    abrirPreviewDocumento(pdfSrc) {
          const dialogRef = this.dialog.open(DialogPreviewDownloadDocumentComponent, {
              data: {
                  pdfSrc: pdfSrc,
                  titulo: 'Poliza',
              }
          });
          dialogRef.afterClosed().subscribe(async result => {
              if (result) {

              }
          });
    }

    async guardarPuntuacion(){
        this.cargando = true;
        try {
            let res = await this.servicePoliza.guardarPuntuacionSolicitud(this.solicitud.puntuacion, this.solicitud.id);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
        }
    }

    abrirEliminarSolicitud() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar solicitud',
                mensaje: '¿Está seguro de que desea eliminar la solicitud' + (this.solicitud.id ? ' #' + this.solicitud.id : '') + '?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    abrirEliminarMapa() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar mapa de proceso',
                mensaje: '¿Está seguro de que desea eliminar el mapa de proceso relacionado a la solicitud' + (this.solicitud.id ? ' #' + this.solicitud.id : '') + '?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminarMapa();
            }
        });
    }

    abrirGenerarNuevoMapa() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Generar mapa de proceso',
                mensaje: '¿Está seguro de que desea generar un nuevo mapa de proceso relacionado a la solicitud' + (this.solicitud.id ? ' #' + this.solicitud.id : '') + '?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.generarMapa();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.servicePoliza.eliminarSolicitudPoliza(this.solicitud.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.cerrar();
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async eliminarMapa(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.mapasProcesosService.eliminarMapaProceso(this.solicitud.MapaProcesoId);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.cerrar();
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async generarMapa(){
        this.cargando = true;

        // Enviar el registro para guardar
        await this.servicePoliza.actualizarSolicitudPoliza(this.solicitud);
        var res = await this.servicePoliza.generarMapaSolicitud(this.solicitud.id, this.solicitud.PolizaId, this.solicitud.PlantillaMapaProcesoId, this.solicitud.UsuarioId);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.cerrar();
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async marcarComoEnviada(){
        this.cargando = true;
        try {
            let res = await this.servicePoliza.marcarComoEnviadaSolicitud(this.solicitud.id);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.solicitud.enviada = true;
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
        }
    }

    actualizarFecha($event){
        if($event && $event.value) {
            this.solicitud.fecha = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarPlantilla() {
        var index = this.libraryService.indexOf(this.plantillas, 'id', this.solicitud.PlantillaSolicitudId);
        if(index != -1) {
            this.solicitud.plantilla = this.plantillas[index];
        }
        this.filtrarPlantillasMapas();
        if(this.solicitud.plantilla && this.solicitud.plantilla.soloIncluidos == true) {
            this.certificadosPolizaFiltrado = this.certificadosPoliza.filter(x => x.estado == 'incluido');
        }
        else if(this.solicitud.plantilla && this.solicitud.plantilla.soloExcluidos == true) {
            this.certificadosPolizaFiltrado = this.certificadosPoliza.filter(x => x.estado == 'excluido');
        }
        else if(this.solicitud.plantilla && this.solicitud.plantilla.ignorarExcluidos == true) {
            this.certificadosPolizaFiltrado = this.certificadosPoliza.filter(x => x.estado != 'excluido');
        }
        else {
            this.certificadosPolizaFiltrado = this.certificadosPoliza;
        }
    }

    paramsEspecialesCertificados() {
        var params = '';
        if(this.solicitud.PolizaId) params += '&PolizaId=' + this.solicitud.PolizaId;

        if(this.solicitud.plantilla && this.libraryService.normalizarString(this.solicitud.plantilla.nombre) == 'exclusion') {
            params += '&estado=excluido';
        }

        if(this.solicitud.plantilla && this.libraryService.normalizarString(this.solicitud.plantilla.nombre) == 'inclusion') {
            params += '&estado=incluido';
        }

        return params;
    }

    paramsEspecialesMensajeria() {
        var params = '';
        params += '&SolicitudPolizaId=' + this.solicitud.id;
        return params;
    }

    filtrarPlantillasMapas() {
        let filtradas: PlantillaMapaProceso[] = [];
        if(this.solicitud.plantilla && this.solicitud.plantilla.tipoUso == 'solicitud') { // No aplica para memos
            for (const plantillaMapa of this.plantillasMapas) {
                if(this.solicitud.plantilla.idsPlantillasMapasArray.indexOf(plantillaMapa.id) != -1) {
                    filtradas.push(plantillaMapa);
                }
            }
        }
        this.plantillasMapasFiltradas = filtradas;
    }

    seleccionarCertificado(id: number) {
        if(!this.solicitud.idsCertificados) this.solicitud.idsCertificados = [];
        if(!this.solicitud.idsCertificados.includes(id)) this.solicitud.idsCertificados.push(id);
        this.actualizarCertificadosSeleccionados();
        this.CertificadoIdTemp = null;
    }

    actualizarCertificadosSeleccionados() {
        // Quitar relaciones que ya no están en los ids seleccionados
        if(!this.solicitud.relacionesCertificado) this.solicitud.relacionesCertificado = [];
        let finales = this.solicitud.relacionesCertificado.filter(x => this.solicitud.idsCertificados.includes(x.CertificadoId));
        for (const CertificadoId of this.solicitud.idsCertificados) {
            let relacionExistente = finales.find(x => x.CertificadoId == CertificadoId);
            if(!relacionExistente) {
                let item = this.certificadosPoliza.find(x => x.id == CertificadoId);
                var nuevaRelacion = new CertificadoSolicitud(null, null, null, this.solicitud.id, item.id, this.auth.idEmpresaActual());
                nuevaRelacion.certificado = item;
                finales.push(nuevaRelacion);
            }
        }
        // Ordenar finales por número de certificado
        finales.sort((a, b) => {
            if(a.certificado.numero > b.certificado.numero) return 1;
            if(a.certificado.numero < b.certificado.numero) return -1;
            return 0;
        });
        this.solicitud.relacionesCertificado = finales;
    }

    // * * * * * Documentos * * * * *
    abrirSubirMultiplesDocumento() {
        const dialogRef = this.dialog.open(DialogAgregarMultiplesDocumentosPolizaComponent, {
            data: {
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result && result.length > 0) {
                for (const el of result) {
                    let doc = new DocumentoPoliza(null, el.nombre, null, el.extension, el.link, null, null, this.solicitud.id, null, this.auth.idEmpresaActual());
                    await this.guardarDocumentoPoliza({registro: doc});
                }
            }
        });
    }

    abrirSubirDocumento() {
        var document = new DocumentoPoliza(-1, null, null, null, null, null, null, this.solicitud.id, null, this.auth.idEmpresaActual());
        const dialogRef = this.dialog.open(DialogAgregarDocumentoPolizaComponent, {
            data: {
                registro: document,
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.guardarDocumentoPoliza(result);
            }
        });
    }

    abrirSeleccionarDocumentosExpediente() {
        const dialogRef = this.dialog.open(DialogAgregarDocumentosExpedienteComponent, {
            data: {
                ReclamoId: this.solicitud.ReclamoId
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result && result.length > 0) {
                let documentos: DocumentoReclamo[] = result;
                for (const el of documentos) {
                    let doc = new DocumentoPoliza(null, el.nombre, el.descripcion, el.extension, el.link, null, null, this.solicitud.id, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
                    await this.guardarDocumentoPoliza({registro: doc});
                }
            }
        });
    }

    async guardarDocumentoPoliza(documento: any) {
        this.cargando = true;
        var res = await this.servicePoliza.guardarDocumentoPoliza(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    async editarDocumentoPoliza(documento: DocumentoPoliza) {
        this.cargando = true;
        var res = await this.servicePoliza.editarDocumentoPoliza(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    abrirEditarDocumento($event) {
        const dialogRef = this.dialog.open(DialogAgregarDocumentoPolizaComponent, {
            data: {
                registro: $event,
                modo: 'detalle'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.editarDocumentoPoliza(result);
            }
        });
    }

    async descargarDocumento(url: string) {
        this.cargando = true;
        if (url) {
            await window.open(url, "_blank");
        }
        this.cargando = false;
    }

    paramsEspecialesDocumentos() {
        var params = '';
        if(this.solicitud.id) params += '&SolicitudPolizaId=' + this.solicitud.id;
        return params;
    }
    
    paramsEspecialesEnvios() {
        var params = '';
        if(this.solicitud.id) params += '&SolicitudPolizaId=' + this.solicitud.id;
        return params;
    }

    abrirEnviarSolicitud() {
        const dialogRef = this.dialog.open(DialogEnviarSolicitudComponent, {
            data: {
                registro: this.solicitud,
                contactos: this.contactosDeAseguradora,
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                this.auth.buscadorReload.next();
            }
        });
    }

    forzar() {
        this.auth.buscadorReload.next();
    }

    seleccionarTodosCertificados() {
        if(!this.solicitud.idsCertificados) this.solicitud.idsCertificados = [];
        let todosSeleccionados = (this.solicitud.idsCertificados.length >= this.certificadosPolizaFiltrado.length);
        this.solicitud.idsCertificados = [];
        if(!todosSeleccionados) {
            for (const certificado of this.certificadosPolizaFiltrado) {
                if(certificado.id) this.solicitud.idsCertificados.push(certificado.id);
            }
        }
        this.actualizarCertificadosSeleccionados();
    }

    estadoDisabled(estadoId: any) {
        if(this.solicitud && this.solicitud.estado) {
            let estadoObjActual = this.apiURLs.estadosSolicitudesPolizas.find(element => element.id == this.solicitud.estado);
            if(estadoObjActual.cambiosPermitidos.includes(estadoId)) return false;
            return true;
        }
        else return false;
    }
}
