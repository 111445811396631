import { Injectable } from '@angular/core';
import { ConnectionService } from '../api/connection.service';
import { APIUrls } from '../api/apiUrls';
import { Usuario } from '../models/usuario';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { JSONConverters } from '../models/JSONConverters';
import { NavigationService } from 'src/@vex/services/navigation.service';
import icUsers from '@iconify/icons-fa-solid/users';
import icHome from '@iconify/icons-fa-solid/home';
import icApartment from '@iconify/icons-fa-solid/city';
import icAnalytics from '@iconify/icons-fa-solid/chart-bar';
import icSettings from '@iconify/icons-fa-solid/cog';
import icAseguradoras from '@iconify/icons-fa-solid/building';
import icRamos from '@iconify/icons-fa-solid/list-ul';
import icPolizas from '@iconify/icons-fa-solid/file-contract';
import icReclamos from '@iconify/icons-fa-solid/exclamation-triangle';
import icCobros from '@iconify/icons-fa-solid/file-invoice-dollar';
import icMaps from '@iconify/icons-fa-solid/project-diagram';
import icCreditos from '@iconify/icons-fa-solid/money-check-alt';
import icProspectos from '@iconify/icons-fa-solid/user-check';
import icControlCalidad from '@iconify/icons-fa-solid/check-double';
import iconMensajeria from '@iconify/icons-fa-solid/envelope';
import icComisiones from '@iconify/icons-fa-solid/money-bill';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { ConfiguracionEmpresa } from '../models/configuracionEmpresa';
import { Moneda } from '../models/moneda';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { Notificacion } from '../models/notificacion';

@Injectable()
export class AuthService {
    private apiUrls: APIUrls = new APIUrls();
    public jsonConverters: JSONConverters = new JSONConverters();
    public maskTarjeta = [ /\d/, /\d/, /\d/, /\d/ ];
    public maskVencimientoTarjeta = [ /\d/, /\d/, /\//, /\d/, /\d/ ];
    usuario: Usuario;
    autenticando: boolean = false;
    buscadorReload: Subject<any> = new Subject();
    generarParams: Subject<any> = new Subject();
    updateParams: Subject<any> = new Subject();
    limpiarBuscador: Subject<any> = new Subject();
    perfilReload: Subject<any> = new Subject();
    totalesProspectosReload: Subject<any> = new Subject();
    totalesProspectosUpdated: Subject<any> = new Subject();
    totalesProspectos: any = {};
    totalesCalidadReload: Subject<any> = new Subject();
    totalesCalidadUpdated: Subject<any> = new Subject();
    totalesCalidad: any = {};
    totalesMensajeriaReload: Subject<any> = new Subject();
    totalesMensajeriaUpdated: Subject<any> = new Subject();
    totalesMensajeria: any = {};
    ultimasNotficaciones: Notificacion[] = [];
    historialBuscador: any = {};

    constructor(
        private router: Router,
        private connection: ConnectionService,
        private navigationService: NavigationService,
        public loader: LoadingBarService,
    ) {
        var usuarioJSON = JSON.parse(localStorage.getItem('usuario'));
        if(usuarioJSON && usuarioJSON.area && usuarioJSON.empresa) {
            var ConfiguracionEmpresaJSON = null;
            if(usuarioJSON.empresa.configuracionEmpresa) ConfiguracionEmpresaJSON = usuarioJSON.empresa.configuracionEmpresa;
            usuarioJSON.empresa.ConfiguracionEmpresas = [ConfiguracionEmpresaJSON];
            usuarioJSON.AreaUsuarioEmpresas = [{
                Area: usuarioJSON.area,
                Empresa: usuarioJSON.empresa,
            }];
        }
        this.usuario = this.jsonConverters.usuarioDeJSON(usuarioJSON);
        this.connection.actualizarUsuario(this.usuario);

        this.totalesProspectosReload.subscribe(event => {
            this.obtenerTotalesProspectos();
        });

        this.totalesCalidadReload.subscribe(event => {
            this.obtenerTotalesCalidad();
        });

        this.totalesMensajeriaReload.subscribe(event => {
            this.obtenerTotalesMensajeria();
        });
    }

    /**
     * Devuelve el id del usuario logueado
     */
    public idUsuarioActual(): number {
        if(this.usuario) return this.usuario.id;
        else return -1;
    }

    public idEmpresaActual(): number {
        if(this.usuario && this.usuario.empresa) return this.usuario.empresa.id;
        else return -1;
    }

    public paisEmpresaActual(): string {
        if(this.usuario && this.usuario.empresa) return this.usuario.empresa.reglasPais;
        else return 'GT';
    }

    public puedeVerTodosProspectos(): boolean {
        if(this.usuario && this.usuario.empresa) return this.usuario.empresa.visualizarTodosProspectos;
        else return false;
    }

    public traduccionCampos(texto: string) {
        let paisActual = this.paisEmpresaActual();
        let traduccion = this.apiUrls.equivalenciasTextos[texto][paisActual];
        if(!traduccion) return texto;
        else return traduccion;
    }

    public tipoMatrizRiesgoEmpresaActual(): string {
        if(this.usuario && this.usuario.empresa) return this.usuario.empresa.tipoMatrizRiesgo;
        else return null;
    }

    public configuracionEmpresaActual(): ConfiguracionEmpresa {
        if(this.usuario && this.usuario.empresa && this.usuario.empresa.configuracionEmpresa) return this.usuario.empresa.configuracionEmpresa;
        else return null;
    }

    public simboloMonedaPorDefecto(): string {
        if(this.usuario && this.usuario.empresa && this.usuario.empresa.moneda) {
            return this.usuario.empresa.moneda.simbolo;
        }
        else return null;
    }

    public idMonedaPorDefecto(): number {
        if(this.usuario && this.usuario.empresa) {
            return this.usuario.empresa.MonedaId;
        }
        else return null;
    }

    setCargaGlobal(cargando: boolean) {
        if(cargando) this.loader.start();
        else this.loader.stop();
    }

    /**
     * Redirige a la página de Not Found (404)
     */
    public irANotFound(){
        this.router.navigate(['/404']);
    }

    /**
     * Orquestra el inicio de sesión de usuario
     * @param correo
     * @param password
     */
    async login(correo: string, password: string) {
        try {
            this.autenticando = true;
            this.usuario = null;
            this.connection.actualizarUsuario(this.usuario);
            // Obtener el token y usuario
            const tokenRes = await this.getToken(correo, password);
            if(tokenRes.error) throw Error(tokenRes.error.mensajeError);
            this.usuario = this.jsonConverters.usuarioDeJSON(tokenRes.data.data);

            // Asignar proyectos de usuario
            var empresasJSON = tokenRes.data.empresas;
            var empresas = [];
            if (empresasJSON) {
                empresasJSON.forEach(element => {
                    empresas.push(this.jsonConverters.empresaDeJSON(element));
                });
            }
            this.usuario.empresas = empresas;

            this.connection.actualizarUsuario(this.usuario);
            // Guardar los datos locales
            localStorage.setItem('token', tokenRes.data.token);
            localStorage.setItem('usuario', JSON.stringify(this.usuario));
            this.cargarMenus();
            // Navegar a inicio
            this.autenticando = false;
            return { error: false, mensaje: null };
        } catch (error) {
            this.autenticando = false;
            this.logoutLocal();
            return { error: true, mensaje: error };
        }
    }

    /**
     * Devuelve el token de autenticación para usuario y contraseña
     * @param correo
     * @param password
     */
    private async getToken(correo: string, password: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/loginWeb';
            var data = { correo: correo, password: password };
            const json = await this.connection.postRequest(url, data, false);
            return { error: null, data: { token: json.token, mensaje: null, data: json.data, empresas: json.empresas } };
        }
        catch(error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    /**
     * Elimina los datos de acceso locales y regresa al login
     */
    public logoutLocal(redirigirNoLogueado: boolean = true){
        this.autenticando = false;
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        this.usuario = null;
        this.connection.actualizarUsuario(this.usuario);
        if(redirigirNoLogueado) this.router.navigate(['/login']);
    }

    /**
     * Cierra la sesión
     */
    public async logout(){
        this.logoutLocal();
    }

    /**
     * Comprueba si el token local es válido para ingresar
     * @param redirigirInicio
     */
    public async comprobarAutenticacion(redirigirInicio: boolean, redirigirNoLogueado: boolean = true) {
        try {
            // Obtener token
            var token = localStorage.getItem('token');
            if(!token) throw Error();
            // Comprobar validez del token
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/verificarToken';
            var data = { token: token };
            const res = await this.connection.postRequest(url, data, false);
            if(res.valido) {
                // Asignar el nuevo token actualizado
                localStorage.setItem('token', res.nuevoToken);
                var nuevoUsuario = this.jsonConverters.usuarioDeJSON(res.data);
                this.usuario = nuevoUsuario;

                var empresasJSON = res.empresas;
                var empresas = [];
                if (empresasJSON) {
                    empresasJSON.forEach(element => {
                        empresas.push(this.jsonConverters.empresaDeJSON(element));
                    });
                }
                this.usuario.empresas = empresas;

                this.connection.actualizarUsuario(this.usuario);
                localStorage.setItem('usuario', JSON.stringify(nuevoUsuario));
                this.cargarMenus();
                if(redirigirInicio) this.router.navigate(['/']);
            }
            else throw Error();
        } catch (error) {
            this.logoutLocal(redirigirNoLogueado);
        }
    }

    public async cambiarLoginEmpresa(EmpresaId: number, redirigirInicio: boolean = true) {
        try {
            this.autenticando = true;
            // Comprobar validez del token
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/cambiarLoginEmpresa';
            var data = { EmpresaId: EmpresaId };
            const res = await this.connection.postRequest(url, data, true);
            if(res.valido) {
                // Asignar el nuevo token actualizado
                localStorage.setItem('token', res.nuevoToken);
                var nuevoUsuario = this.jsonConverters.usuarioDeJSON(res.data);
                this.usuario = nuevoUsuario;

                var empresasJSON = res.empresas;
                var empresas = [];
                if (empresasJSON) {
                    empresasJSON.forEach(element => {
                        empresas.push(this.jsonConverters.empresaDeJSON(element));
                    });
                }
                this.usuario.empresas = empresas;

                this.connection.actualizarUsuario(this.usuario);
                localStorage.setItem('usuario', JSON.stringify(nuevoUsuario));
                this.cargarMenus();
                this.autenticando = false;
                if(redirigirInicio) {
                    this.router.navigate(['/']);
                    setTimeout(() => {
                        window.location.reload();
                    }, 50);
                }
            }
            else throw Error();
        } catch (error) {
            this.autenticando = false;
            this.logoutLocal();
        }
    }

    public authGuardLoggedIn(): boolean {
        var missingInfo = false;
        var token = localStorage.getItem('token');
        if(!token || token.trim() == '') missingInfo = true;

        if(!this.usuario || !this.usuario.empresa || !this.usuario.area) missingInfo = true;

        if (missingInfo) {
            this.logoutLocal();
            return false;
        }
        return true;
    }

    public esCliente(): boolean {
        if(!this.usuario) return false;
        else return this.usuario.esCliente;
    }

    /**
     * Tokens de auth
     */
    public getAuthToken(): string {
        var token = localStorage.getItem('token');
        return token;
    }

    public maskHora(){
        var horaMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
        return horaMask;
    }

    public maskDPI(){
        var dpiMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/ ];
        return dpiMask;
    }

    public maskNIT(){
        var mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /./ ];
        return mask;
    }

    public optionsMonedaLocal() {
        return { prefix: this.currencySymbol() + ' ', align: 'left', allowNegative: false, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
    }

    public optionsMonedaId(MonedaId: number, monedas: Moneda[], negativo: boolean = false) {
        var simbolo = this.currencySymbolMonedaId(MonedaId, monedas);
        return { prefix: simbolo + ' ', align: 'left', allowNegative: negativo, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
    }

    public optionsMonedaId6Decimales(MonedaId: number, monedas: Moneda[], negativo: boolean = false) {
        var simbolo = this.currencySymbolMonedaId(MonedaId, monedas);
        return { prefix: simbolo + ' ', precision: 6, align: 'left', allowNegative: negativo, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
    }

    public optionsMoneda(simbolo) {
        return { prefix: simbolo + ' ', align: 'left', allowNegative: false, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
    }

    public optionsMonedaSinSimbolo() {
        return { prefix: ' ', align: 'left', allowNegative: false, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
    }

    public optionsPorcentaje(max100: boolean = false) {
        let options: any = { suffix: '%', prefix: '', align: 'left', allowNegative: false, precision: 4, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
        if(max100) options.max = 100;
        return options;
    }

    public optionsPorcentaje2Decimales(max100: boolean = false) {
        let options: any = { suffix: '%', prefix: '', align: 'left', allowNegative: false, precision: 2, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
        if(max100) options.max = 100;
        return options;
    }

    public optionsPorcentajeTasa(max100: boolean = false) {
        let options: any = { suffix: '%', prefix: '', align: 'left', allowNegative: false, precision: 5, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
        if(max100) options.max = 100;
        return options;
    }

    public optionsNumeroEntero(maximo: number = null) {
        let options: any = { suffix: '', prefix: '', align: 'left', allowNegative: false, precision: 0, inputMode: CurrencyMaskInputMode.NATURAL, nullable: true };
        if(maximo != null) options.max = maximo;
        return options;
    }

    currencySymbol() {
        return this.simboloMonedaPorDefecto();
    }

    currencySymbolMonedaId(MonedaId: number, monedas: Moneda[]) {
        var simbolo = '';
        if(monedas) {
            for (const moneda of monedas) {
                if(moneda && moneda.id == MonedaId) {
                    simbolo = moneda.simbolo;
                    break;
                }
            }
        }
        return simbolo;
    }

    // * * * Permisos * * *
    tienePermisoPara(modulo: string, nivel: string) {
        if(!this.usuario || !this.usuario.area || !this.usuario.empresa) return false;
        return this.usuario.area.tienePermisoPara(modulo, nivel);
    }

    tienePermisoExactoPara(modulo: string, nivel: string) {
        if(!this.usuario || !this.usuario.area || !this.usuario.empresa) return false;
        return this.usuario.area.tienePermisoExactoPara(modulo, nivel);
    }

    esAccesoACliente() {
        if(this.usuario && this.usuario.esAccesoACliente != null) return this.usuario.esAccesoACliente == true;
        return false;
    }

    esAdministradorAgrupador() {
        if(this.usuario && this.usuario.esAdministradorAgrupador != null) return this.usuario.esAdministradorAgrupador == true;
        return false;
    }

    idsAgentesCartera() {
        if(this.usuario && this.usuario.idsAgentesCarteraArray && this.usuario.idsAgentesCarteraArray.length > 0) return this.usuario.idsAgentesCarteraArray;
        else return null;
    }

    idsVendedoresCartera() {
        if(this.usuario && this.usuario.idsVendedoresCarteraArray && this.usuario.idsVendedoresCarteraArray.length > 0) return this.usuario.idsVendedoresCarteraArray;
        else return null;
    }

    // * * * Menú * * *
    cargarMenus() {
        // Carga de totales
        this.obtenerTotalesProspectos();
        this.obtenerTotalesCalidad();
        this.obtenerTotalesMensajeria();

        // Inicio
        var items: NavigationItem[] = [{
            type: 'link',
            label: 'Inicio',
            route: '/',
            icon: icHome
        }];

        var modulos = this.apiUrls.modulos;
        var nivelesAutorizacion = this.apiUrls.nivelesAutorizacion;

        // * * * Clientes * * *
        if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Clientes',
                icon: icUsers,
                children: [],
            };

            // Listado clientes
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos los clientes',
                    route: '/clientes/'
                });
            }

            // Crear cliente
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.ESCRITURA) && !this.esAdministradorAgrupador() && !this.esAccesoACliente()) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo cliente',
                    route: '/clientes/nuevo'
                });
            }

            // Listado agrupadores
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA) && !this.esAdministradorAgrupador() && !this.esAccesoACliente()) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos los agrupadores',
                    route: '/clientes/agrupadores/'
                });
            }

            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA) && this.esAdministradorAgrupador() && this.usuario.AgrupadorId) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Datos de agrupador',
                    route: '/clientes/editar-agrupador/' + this.usuario.AgrupadorId
                });
            }

            // Crear agrupador
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.ESCRITURA) && !this.esAdministradorAgrupador() && !this.esAccesoACliente()) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo agrupador',
                    route: '/clientes/nuevo-agrupador'
                });
            }

            // Formularios
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA) && !this.esAdministradorAgrupador() && !this.esAccesoACliente()) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Formularios y plantillas',
                    route: '/clientes/formularios'
                });
            }

            // Formularios
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA) && !this.esAdministradorAgrupador() && !this.esAccesoACliente()) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitudes pendientes',
                    route: '/solicitudes/clientes-pendiente'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitudes en proceso',
                    route: '/solicitudes/clientes-en-proceso'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitudes finalizadas',
                    route: '/solicitudes/clientes-finalizado'
                });
            }

            // Carga masiva
            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.ESCRITURA) && !this.esAdministradorAgrupador() && !this.esAccesoACliente()) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga masiva',
                    route: '/clientes/importacion-clientes'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Pólizas * * *
        if(this.tienePermisoPara(modulos.POLIZAS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Pólizas',
                icon: icPolizas,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.POLIZAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todas las pólizas',
                    route: '/polizas/'
                });
            }

            // Crear
            if(this.tienePermisoPara(modulos.POLIZAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nueva póliza',
                    route: '/polizas/nuevo'
                });
            }

            if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Carga masiva requerimientos',
                    route: '/cobros/importacion-cobros'
                });
            }

            // Plantillas de ramos
            if(this.tienePermisoPara(modulos.PLANTILLAS_RAMOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Plantillas de ramos',
                    route: '/ramos/plantillas'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Reclamos * * *
        if(this.tienePermisoPara(modulos.RECLAMOS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Reclamos',
                icon: icReclamos,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.RECLAMOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos los reclamos',
                    route: '/reclamos/todos'
                });
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reclamos daños',
                    route: '/reclamos/danos'
                });
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reclamos personas',
                    route: '/reclamos/personas'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Autorizaciones',
                    route: '/reclamos/emergencias'
                });
            }

            // Crear
            if(this.tienePermisoPara(modulos.RECLAMOS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo reclamo',
                    route: '/reclamos/nuevo'
                });
            }

            if(this.tienePermisoPara(modulos.RECLAMOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mis seguimientos pendientes',
                    route: '/reclamos/seguimientos/pendientes'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Cobros * * *
        if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Cobros',
                icon: icCobros,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Por cobrar',
                    route: '/cobros/cobro'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Cobrados',
                    route: '/cobros/cobrado'
                });

                // menuGeneral.children.push({
                //     type: 'link',
                //     label: 'Liquidados',
                //     route: '/cobros/liquidado'
                // });

                if(!this.esAdministradorAgrupador()) {
                    menuGeneral.children.push({
                        type: 'link',
                        label: 'Anulados',
                        route: '/cobros/anulado'
                    });

                    menuGeneral.children.push({
                        type: 'link',
                        label: 'Planillas de cobro',
                        route: '/cobros/planillas'
                    });
                }

                if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.ADMINISTRADOR)) {
                    menuGeneral.children.push({
                        type: 'link',
                        label: 'Plantillas de gestión de cobro',
                        route: '/cobros/plantillas-gestiones-cobros'
                    });
                }
                if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.LECTURA)) {
                    menuGeneral.children.push({
                        type: 'link',
                        label: 'Gestiones de cobro',
                        route: '/cobros/gestiones-cobros'
                    });
                }
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Créditos * * *
        if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Créditos',
                icon: icCreditos,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos',
                    route: '/cobros/creditos-todos'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Por aplicar',
                    route: '/cobros/creditos-por-aplicar'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Aplicados',
                    route: '/cobros/creditos-aplicados'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Notas de devolución',
                    route: '/cobros/creditos-notas-devolucion'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Primas en depósito',
                    route: '/cobros/creditos-primas-deposito'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Créditos pagados',
                    route: '/cobros/creditos-pagados'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Comisiones * * *
        if(this.tienePermisoPara(modulos.COMISIONES.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Comisiones',
                icon: icComisiones,
                children: [],
            };

            menuGeneral.children.push({
                type: 'link',
                label: 'Liquidados',
                route: '/cobros/liquidado'
            });

            menuGeneral.children.push({
                type: 'link',
                label: 'Planillas de liquidación',
                route: '/cobros/planillas-liquidacion'
            });

            if(this.tienePermisoPara(modulos.COMISIONES.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Liquidación de cobros',
                    route: '/cobros/liquidacion-cobros'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Mapas de proceso * * *
        if(this.tienePermisoPara(modulos.MAPAS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Mapas de proceso',
                icon: icMaps,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.MAPAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mis pendientes',
                    route: '/mapas-procesos/pendientes',
                    badgeMapasProcesos: 'pendiente',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Creados por mí',
                    route: '/mapas-procesos/creados'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos mis mapas',
                    route: '/mapas-procesos/todos'
                });

                if(this.tienePermisoPara(this.apiUrls.modulos.MAPAS.id, this.apiUrls.nivelesAutorizacion.ADMINISTRADOR)) {
                    menuGeneral.children.push({
                        type: 'link',
                        label: 'Todos los mapas',
                        route: '/mapas-procesos/general'
                    });
                }
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Mensajería * * *
        if(this.tienePermisoPara(modulos.MENSAJERIA.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Mensajería',
                icon: iconMensajeria,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.MENSAJERIA.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos los mensajes',
                    route: '/mensajeria/todos',
                    badgeMensajeria: 'todos',
                });
            }

            if(this.tienePermisoPara(modulos.MENSAJERIA.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mensajería pendiente',
                    route: '/mensajeria/pendientes',
                    badgeMensajeria: 'pendientes',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mi mensajería',
                    route: '/mensajeria/mis-mensajes',
                });
            }

            // Agregar
            if(this.tienePermisoPara(modulos.MENSAJERIA.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitar envío',
                    route: '/mensajeria/nuevo/envio',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Registrar recepción',
                    route: '/mensajeria/nuevo/recepcion',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Ruta mensajería',
                    route: '/mensajeria/ruta-mensajeria',
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Prospectos * * *
        if(
            this.tienePermisoPara(modulos.PROSPECTOS.id, nivelesAutorizacion.LECTURA) ||
            this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Prospectos',
                icon: icProspectos,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.PROSPECTOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Nuevos',
                    route: '/prospectos/nuevos',
                    badgeProspecto: 'nuevo',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'En negociación',
                    route: '/prospectos/negociacion',
                    badgeProspecto: 'negociacion',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pospuestos',
                    route: '/prospectos/pospuesto',
                    badgeProspecto: 'pospuesto',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Ganados',
                    route: '/prospectos/ganado',
                    badgeProspecto: 'ganado',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Perdidos',
                    route: '/prospectos/perdido',
                    badgeProspecto: 'perdido',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pendientes de asignar',
                    route: '/prospectos/pendiente-asignar',
                    badgeProspecto: 'pendiente-asignar',
                });
            }

            if(this.tienePermisoPara(modulos.CLIENTES.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pendientes de crear cliente',
                    route: '/prospectos/ganado-pendiente-cliente',
                    badgeProspecto: 'ganado-pendiente-cliente',
                });
            }

            if(this.tienePermisoPara(modulos.PROSPECTOS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar prospecto',
                    route: '/prospectos/nuevo'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Control de calidad * * *
        if(this.tienePermisoPara(modulos.CONTROL_CALIDAD.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Control de calidad',
                icon: icControlCalidad,
                children: [],
            };

            // Listado
            if(this.tienePermisoPara(modulos.CONTROL_CALIDAD.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitudes pendientes',
                    route: '/polizas/solicitudes-pendientes-calidad',
                    badgeCalidad: 'pendiente',
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Solicitudes revisadas',
                    route: '/polizas/solicitudes-revisadas-calidad',
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Analytics * * *
        if (
            this.tienePermisoPara(modulos.CLIENTES_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.POLIZAS_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.RECLAMOS_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.PROSPECTOS_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.PROSPECTOS_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.MAPAS_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.CONTROL_CALIDAD_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.MENSAJERIA_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.COBROS_ANALYTICS.id, nivelesAutorizacion.PROPIO) ||
            this.tienePermisoPara(modulos.COMISIONES_ANALYTICS.id, nivelesAutorizacion.PROPIO)
        ) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Analytics',
                icon: icAnalytics,
                children: [],
                modoAdmin: true,
            };

            // Listado
            if (this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mis analytics',
                    route: '/analytics/mis-analytics'
                });
            }

            if (this.tienePermisoPara(modulos.CLIENTES_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Clientes',
                    route: '/clientes/analytics'
                });
            }

            if (this.tienePermisoPara(modulos.POLIZAS_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pólizas',
                    route: '/polizas/analytics'
                });
            }

            if (this.tienePermisoPara(modulos.RECLAMOS_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reclamos',
                    route: '/reclamos/analytics'
                });
            }

            if(this.tienePermisoPara(modulos.PROSPECTOS_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Prospectos',
                    route: '/prospectos/analytics'
                });
            }

            if(this.tienePermisoPara(modulos.MAPAS_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mapas de proceso',
                    route: '/mapas-procesos/analytics'
                });
            }

            if(this.tienePermisoPara(modulos.MAPAS_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Reporte de solicitudes',
                    route: '/mapas-procesos/reporte-solicitudes'
                });
            }

            if(this.tienePermisoPara(modulos.CONTROL_CALIDAD_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Control de calidad',
                    route: '/polizas/analytics-control-calidad'
                });
            }

            if(this.tienePermisoPara(modulos.MENSAJERIA_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Mensajería',
                    route: '/mensajeria/analytics'
                });
            }

            if(this.tienePermisoPara(modulos.COBROS_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Cobros',
                    route: '/cobros/analytics'
                });
            }

            if(this.tienePermisoPara(modulos.COMISIONES_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Comisiones',
                    route: '/cobros/analytics-comisiones'
                });
            }

            if(this.tienePermisoPara(modulos.COMISIONES_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Comisiones proyectadas',
                    route: '/cobros/comisiones-proyectadas'
                });
            }

            if(this.tienePermisoPara(modulos.COMISIONES_ANALYTICS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Comisiones por agente',
                    route: '/cobros/reporte-comisiones-agentes'
                });
            }

            if(this.tienePermisoPara(modulos.CLIENTES_ANALYTICS.id, nivelesAutorizacion.LECTURA) && this.tipoMatrizRiesgoEmpresaActual() == 'costa-rica') {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Matriz de riesgo',
                    route: '/clientes/reporte-matriz-riesgo'
                });
            }

            if (menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Aseguradoras * * *
        if(this.tienePermisoPara(modulos.ASEGURADORAS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Aseguradoras',
                icon: icAseguradoras,
                children: [],
                modoAdmin: true,
            };

            // Listado
            if(this.tienePermisoPara(modulos.ASEGURADORAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todas las aseguradoras',
                    route: '/aseguradoras/'
                });
            }

            // Crear
            if(this.tienePermisoPara(modulos.ASEGURADORAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nueva aseguradora',
                    route: '/aseguradoras/nuevo'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }


        // * * * Ramos * * *
        if(this.tienePermisoPara(modulos.RAMOS.id, nivelesAutorizacion.LECTURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Ramos',
                icon: icRamos,
                children: [],
                modoAdmin: true,
            };

            // Listado
            if(this.tienePermisoPara(modulos.RAMOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todos los ramos',
                    route: '/ramos/'
                });
            }

            // Crear
            if(this.tienePermisoPara(modulos.RAMOS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nuevo ramo',
                    route: '/ramos/nuevo'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Empresas * * *
        if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.ESCRITURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Empresas',
                icon: icApartment,
                children: [],
                modoAdmin: true,
            };

            // Listado
            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Todas las empresas',
                    route: '/empresas/'
                });
            }

            // Crear
            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Agregar nueva empresa',
                    route: '/empresas/nuevo'
                });
            }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        // * * * Configuración de empresa * * *
        if(this.tienePermisoPara(modulos.DATOS_GENERALES.id, nivelesAutorizacion.ESCRITURA)) {
            // Menú general
            var menuGeneral: NavigationItem = {
                type: 'dropdown',
                label: 'Configuración',
                icon: icSettings,
                children: [],
                modoAdmin: true,
            };

            // Configuración
            if(this.tienePermisoPara(modulos.POLIZAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Pruebas de avisos',
                    route: '/avisos/envio-pruebas'
                });
            }

            // Configuración
            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Opciones',
                    route: '/empresas/perfil'
                });
            }

            // Tipos de cambio
            if(this.tienePermisoPara(modulos.PAISES_MONEDAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Tipo de cambio',
                    route: '/empresas/tiposDeCambio'
                });
            }

            // Plantillas de mapas de proceso
            if(this.tienePermisoPara(modulos.DATOS_GENERALES.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Plantillas de mapas',
                    route: '/mapas-procesos/plantillas'
                });
            }

            // Plantillas de solicitudes
            if(this.tienePermisoPara(modulos.DATOS_GENERALES.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Plantillas de solicitudes',
                    route: '/solicitudes/plantillas'
                });
            }

            // Listado de usuarios
            if(this.tienePermisoPara(modulos.USUARIOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Usuarios',
                    route: '/usuarios/'
                });
            }

            // Listado de áreas
            if(this.tienePermisoPara(modulos.AREAS_ACCESOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Áreas administrativas',
                    route: '/areas/areas'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Áreas de clientes',
                    route: '/areas/areas-clientes'
                });

                menuGeneral.children.push({
                    type: 'link',
                    label: 'Operaciones de acceso',
                    route: '/areas/operaciones-accesos'
                });
            }

            // Listado de razones de pérdida prospectos
            if(this.tienePermisoPara(modulos.PROSPECTOS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Razones de pérdida de prospectos',
                    route: '/prospectos/razones-perdidas'
                });
            }

            // Listado de razones de pérdida prospectos
            if(this.tienePermisoPara(modulos.DATOS_GENERALES.id, nivelesAutorizacion.ADMINISTRADOR)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Ocupaciones',
                    route: '/prospectos/ocupaciones'
                });
            }

            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Países',
                    route: '/empresas/paises'
                });
            }

            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.LECTURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Departamentos',
                    route: '/empresas/departamentos'
                });
            }

            // Cobradores
            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Cobradores',
                    route: '/cobros/cobradores'
                });
            }

            // Bancos
            if (this.tienePermisoPara(modulos.COBROS.id, nivelesAutorizacion.ADMINISTRADOR)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Bancos',
                    route: '/cobros/bancos/todos'
                });

            }

            // Tipos de Vehiculos
            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.ESCRITURA)) {
                menuGeneral.children.push({
                    type: 'link',
                    label: 'Tipos de Vehículos',
                    route: '/empresas/tiposVehiculos'
                });
            }

            if(this.tienePermisoPara(modulos.EMPRESAS.id, nivelesAutorizacion.LECTURA)) {
              menuGeneral.children.push({
                  type: 'link',
                  label: 'Marcas vehículos',
                  route: '/empresas/marcas-vehiculos'
              });
          }

            if(menuGeneral.children.length > 0) {
                items.push(menuGeneral);
            }
        }

        var primeroAdmin = false;
        for (let i = 0; i < items.length; i++) {
            var item = items[i];
            if(item.modoAdmin == true && !primeroAdmin) {
                primeroAdmin = true;
            }
            else {
                items[i].modoAdmin = false;
            }
        }

        this.navigationService.items = items;
    }

    // * * * Totales de prospectos * * *
    public async obtenerTotalesProspectos() {
        try {
            if(this.tienePermisoPara(this.apiUrls.modulos.PROSPECTOS.id, this.apiUrls.nivelesAutorizacion.LECTURA)) {
                var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/totalesVendedor/' + this.idUsuarioActual();
                var json = await this.connection.getRequest(url);
                this.totalesProspectos = {};
                if(json.totales) {
                    for (let i = 0; i < json.totales.length; i++) {
                        const element = json.totales[i];
                        this.totalesProspectos[element.etapa] = element.total;
                    }
                }
                this.totalesProspectosUpdated.next();
            }
            return true;
        } catch (error) {
            return false;
        }
    }

    // * * * Totales de calidad * * *
    public async obtenerTotalesCalidad() {
        try {
            if(this.tienePermisoPara(this.apiUrls.modulos.CONTROL_CALIDAD.id, this.apiUrls.nivelesAutorizacion.LECTURA)) {
                var url = this.apiUrls.baseURL + this.apiUrls.controlCalidadURL + '/totalesCalidad/' + this.idUsuarioActual();
                var json = await this.connection.getRequest(url);
                this.totalesCalidad = {};
                if(json.totales) {
                    for (let i = 0; i < json.totales.length; i++) {
                        const element = json.totales[i];
                        this.totalesCalidad[element.estadoControlCalidad] = element.total;
                    }
                }
                this.totalesCalidadUpdated.next();
            }
            return true;
        } catch (error) {
            return false;
        }
    }

    // * * * Totales de mensajería * * *
    public async obtenerTotalesMensajeria() {
        try {
            if(this.tienePermisoPara(this.apiUrls.modulos.MENSAJERIA.id, this.apiUrls.nivelesAutorizacion.LECTURA)) {
                var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/totalesUsuario/' + this.idUsuarioActual();
                var json = await this.connection.getRequest(url);
                this.totalesMensajeria = {};
                if(json.totales) {
                    for (let i = 0; i < json.totales.length; i++) {
                        const element = json.totales[i];
                        this.totalesMensajeria[element.etapa] = element.total;
                    }
                }
                this.totalesMensajeriaUpdated.next();
            }
            return true;
        } catch (error) {
            return false;
        }
    }

    // * * * Obtener las notificaciones * * * //
    public async obtenerUltimasNotificaciones(){
        await this.getNotificacion();
        setInterval(async ()=>{
            const url = this.apiUrls.baseURL + this.apiUrls.notificacionesURL + '/obtenerUltimas/';
            var json = await this.connection.getRequest(url);
            let array = [];
            json.result.forEach(element => {
                array.push(this.jsonConverters.notificacionDeJSON(element));
            });
            this.ultimasNotficaciones = array;
            array = [];
        }, 1200000);
    }

    private async getNotificacion(){
        const url = this.apiUrls.baseURL + this.apiUrls.notificacionesURL + '/obtenerUltimas/';
        var json = await this.connection.getRequest(url);
        let array = [];
        json.result.forEach(element => {
            array.push(this.jsonConverters.notificacionDeJSON(element));
        });
        this.ultimasNotficaciones = array;
        array = [];
    }

    public async marcarLeido(id:number){
        const url = this.apiUrls.baseURL + this.apiUrls.notificacionesURL + '/marcarLeida/';
        var json = await this.connection.postReq(url,id);
        if(!json.error){
            return true;
        }
        return false;
    }

    // * * * Historial de buscador * * *
    agregarHistorialBuscador(rutaCompleta, params) {
        if(rutaCompleta) {
            let partes = rutaCompleta.split('?');
            if(!this.historialBuscador) this.historialBuscador = {};
            this.historialBuscador[partes[0]] = params;
        }
    }

    obtenerParamsRutaHistorial(ruta) {
        if(ruta && this.historialBuscador) {
            return this.historialBuscador[ruta];
        }
    }
}
