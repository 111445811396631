export class OperacionAcceso {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public modulo: string,
        public accion: string,
        public activo: boolean,
        public EmpresaId: number,
    ){}
}