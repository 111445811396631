import { Usuario } from "./usuario";

export class DocumentoReclamo {
    constructor(
        public id:              number,
        public nombre:          string,
        public descripcion:     string,
        public extension:       string,
        public link:            string,

        // Relaciones
        public ReclamoId:         number,
        public UsuarioId:         number,
        public EmpresaId:         number,
    ){}

    nombreUsuario: string;
    usuario: Usuario;
    seleccionado: boolean = false;
}