import { Moneda } from './moneda';
import { ConfiguracionEmpresa } from './configuracionEmpresa';
import { ImpuestoEmpresa } from './impuestoEmpresa';
import { FormularioCliente } from './formularioCliente';

export class Empresa {
    constructor(
        public id: number,
        public nombre: string,
        public direccion: string,
        public telefono: string,
        public telefonoEmergencia: string,
        public website: string,
        public tipoMatrizRiesgo: string,
        public emailServicioAlCliente: string,
        public emailSoporteTecnico: string,
        public logo: string,
        public logoDocumentos: string,
        public reglasPais: string,
        public registro: string,
        public visualizarTodosProspectos: boolean,
        public bandera: string,
        public MonedaId: number,
        public RazonPerdidaId: number,
    ){}

    public moneda: Moneda;
    public idsMonedas: number[] = [];
    public monedas: Moneda[];
    public configuracionEmpresa: ConfiguracionEmpresa;
    public impuestos: ImpuestoEmpresa[];
    public formulariosClientes: FormularioCliente[];
}