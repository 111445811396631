import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsuariosRoutingModule } from './usuarios-routing.module';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { InicioComponent } from './inicio/inicio.component';
import { NuevoUsuarioComponent } from './nuevo-usuario/nuevo-usuario.component';
import { MatInputModule, MatOptionModule, MatIconModule, MatSelectModule, MatButtonModule, MatProgressBarModule, MatCheckboxModule, MatDatepickerModule, MatTabsModule, MatCardModule, MatRadioModule, MatPaginatorModule } from '@angular/material';
import { IconModule } from '@visurel/iconify-angular';
import { PerfilComponent } from './perfil/perfil.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCurrencyModule } from "ngx-currency";
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { ImportacionEjecutivosAgentesComponent } from './importacion-ejecutivos-agentes/importacion-ejecutivos-agentes.component';
import { DialogOperacionAccesoUsuario } from './dialog-operacion-acceso-usuario/dialog-operacion-acceso-usuario.component';

@NgModule({
    imports: [
        CommonModule,
        UsuariosRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        LibraryModule,
        NgxDatatableModule,
        NgxCurrencyModule,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        // Material
        MatInputModule,
        MatOptionModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCardModule,
        MatTabsModule,
        MatPaginatorModule,
        IconModule,
        MatCheckboxModule,
        FlexLayoutModule,
        PageLayoutModule,
    ],
    declarations: [
        UsuariosComponent,
        InicioComponent,
        NuevoUsuarioComponent,
        PerfilComponent,
        ImportacionEjecutivosAgentesComponent,
        DialogOperacionAccesoUsuario,
    ],
    entryComponents: [
        DialogOperacionAccesoUsuario,
    ]
})
export class UsuariosModule {
}
