import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { SeguimientoReclamo } from 'src/app/models/seguimientoReclamo';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Reclamo } from 'src/app/models/reclamo';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { OperacionAcceso } from 'src/app/models/operacionAcceso';
import { AreasService } from 'src/app/areas/areas.service';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    OperacionAccesoId: number,
    UsuarioId: number,
    modoCreacion: boolean,
}

@Component({
    selector: 'dialog-operacion-acceso-usuario',
    templateUrl: './dialog-operacion-acceso-usuario.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogOperacionAccesoUsuario {
    apiURLs = new APIUrls();
    modoDetalle: boolean = false;
    operaciones: OperacionAcceso[];
    iconDelete = iconDelete;
    cargando: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DialogOperacionAccesoUsuario>,
        public service: AuthService,
        public areasService: AreasService,
        public auth: AuthService,
        public dialog: MatDialog,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        this.obtenerOperaciones();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
            OperacionAccesoId: this.data.OperacionAccesoId,
            UsuarioId: this.data.UsuarioId,
        });
    }

    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar acceso',
                mensaje: '¿Está seguro de que desea eliminar este acceso para el usuario?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    eliminar() {
        this.dialogRef.close({
            accion: 'eliminar',
            OperacionAccesoId: this.data.OperacionAccesoId,
            UsuarioId: this.data.UsuarioId,
        });
    }

    registroValido(): boolean {
        if(!this.data.OperacionAccesoId) return false;
        
        return true;
    }

    async obtenerOperaciones() {
        this.cargando = true;
        let res = await this.areasService.obtenerTodasOperacionesAcceso();
        if(res.error) {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'error');
        }
        else {
            this.operaciones = res.data.registros;
        }
        this.cargando = false;
    }
}