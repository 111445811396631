import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Poliza } from '../models/poliza';
import { Certificado } from '../models/certificado';
import { Declaracion } from '../models/declaracion';
import { RevisionPoliza } from '../models/revisionPoliza';
import * as FileSaver from 'file-saver';
import { SolicitudPoliza } from '../models/solicitudPoliza';
import { Usuario } from '../models/usuario';
import * as Papa from 'papaparse';
import { Aseguradora } from '../models/aseguradora';
import { Agrupador } from '../models/agrupador';
import { Moneda } from '../models/moneda';
import { Ramo } from '../models/ramo';
import { Producto } from '../models/producto';
import { DocumentoPoliza } from '../models/documentoPoliza';
import { TipoCertificado } from '../models/tipoCertificado';
import { PlantillaRamo } from '../models/plantillaRamo';
import { ValorCertificado } from '../models/valorCertificado';
import { CampoTipoCertificado } from '../models/campoTipoCertificado';
import { BeneficiarioDependienteCertificado } from '../models/beneficiarioDependienteCertificado';
import { PagadorCertificado } from '../models/pagadorCertificado';
import * as _moment from 'moment';
import { AclaracionPoliza } from '../models/aclaracionPoliza';
import { DetalleDeclaracion } from '../models/detalleDeclaracion';
import { ClausulaGarantiaPoliza } from '../models/clausulaGarantiaPoliza';
import { AuthService } from '../auth/auth.service';
import { EnvioSolicitud } from '../models/envioSolicitud';
import { DireccionCertificado } from '../models/direccionCertificado';
const moment = _moment;

@Injectable()
export class PolizasService {
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
        public auth: AuthService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    async obtenerPolizaPorId(id: number, incluirCertificados: boolean = true) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/id/' + id;
            if(incluirCertificados) url += '?&incluirCertificados=1';
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.polizaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerSiguienteNumeroCertificado(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/proximoCertificado/' + PolizaId;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { correlativo: json.correlativo } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Marcar como corregida
    public async marcarPolizaCorregida(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/marcarCorregida';
            const res = await this.connection.postRequest(url, { PolizaId: PolizaId });
            return { error: null, data: { mensaje: 'Póliza actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async guardarPagadoresCertificadoPagadorPoliza(PolizaId: number, PagadorId: number, certificadosPagador: PagadorCertificado[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/pagadoresCertificadoPagadorPoliza';
            const res = await this.connection.postRequest(url, {
                PolizaId: PolizaId,
                PagadorId: PagadorId,
                certificadosPagador: certificadosPagador,
            });
            return { error: null, data: { mensaje: 'Certificados actualizados', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearPoliza(registro: Poliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarPoliza(registro: Poliza, revision: RevisionPoliza = null) {
        try {
            if(revision) {
                registro.revision = revision;
            }
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPoliza(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/id/' + PolizaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Poliza eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Cancelar póliza
    public async cancelarPoliza(PolizaId: number, fechaVigenciaFin: string, RazonPerdidaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/marcarCancelada';
            var data = {
                PolizaId: PolizaId,
                fechaVigenciaFin: fechaVigenciaFin,
                RazonPerdidaId: RazonPerdidaId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Póliza cancelada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Renovar póliza
    public async renovarPoliza(PolizaId: number, mismaAseguradora: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/renovar';
            var data = {
                PolizaId: PolizaId,
                mismaAseguradora: mismaAseguradora,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Póliza renovada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Marcar vigente póliza
    public async marcarVigentePoliza(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/marcarVigente';
            var data = {
                PolizaId: PolizaId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Póliza duplicada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerTodosTiposPolizas() {
        try {
            var registros = this.apiUrls.tiposPolizas;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las tipos de campos.');
        }
    }

    async obtenerTodosCaracteresPolizas() {
        try {
            var registros = this.apiUrls.caracteresPolizas;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las tipos de campos.');
        }
    }

    async obtenerTodosFrecuenciasPagos() {
        try {
            var registros = this.apiUrls.frecuenciasPagos;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las frecuencias de pago.');
        }
    }

    async obtenerTodosHorasVigencia() {
        try {
            var registros = this.apiUrls.horasVigencia;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las frecuencias de pago.');
        }
    }

    async obtenerTodosMapasPoliza(PolizaId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/search?PolizaId=' + PolizaId;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.records.length; i++) {
                const element = json.records[i];
                registros.push(this.jsonConverters.mapaProcesoDeJSON(element));
            }
            return {
                error: null,
                data: {
                    registros: registros
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los mapas de proceso de la póliza.');
        }
    }

    async obtenerTodasPolizasResumen() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/resumen';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.polizaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las pólizas.');
        }
    }

    async obtenerTodasPolizasCliente(ClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/cliente/' + ClienteId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.polizaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las pólizas.');
        }
    }

    async obtenerTodasPolizasPagador(ClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/pagador/' + ClienteId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.polizaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las pólizas.');
        }
    }

    async buscadorGlobal(busqueda: string, otrosParams: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/search?&limit=10&field=global';
            if(busqueda && busqueda.trim() != '') url += '&value=' + busqueda.trim();
            if(otrosParams) url += otrosParams;
            var json = await this.connection.getRequest(url);

            var polizasPorNumero = [];
            for (let i = 0; i < json.polizasPorNumero.length; i++) {
                const element = json.polizasPorNumero[i];
                polizasPorNumero.push(this.jsonConverters.polizaDeJSON(element));
            }

            var polizasPorCliente = [];
            for (let i = 0; i < json.polizasPorCliente.length; i++) {
                const element = json.polizasPorCliente[i];
                polizasPorCliente.push(this.jsonConverters.polizaDeJSON(element));
            }

            var certificados = [];
            for (let i = 0; i < json.certificados.length; i++) {
                const element = json.certificados[i];
                certificados.push(this.jsonConverters.certificadoDeJSON(element));
            }

            var clientes = [];
            for (let i = 0; i < json.clientes.length; i++) {
                const element = json.clientes[i];
                clientes.push(this.jsonConverters.usuarioDeJSON(element));
            }

            return {
                error: null,
                data: {
                    polizasPorNumero: polizasPorNumero,
                    polizasPorCliente: polizasPorCliente,
                    certificados: certificados,
                    clientes: clientes,
                    totalPolizasPorNumero: json.totalPolizasPorNumero,
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los mapas de proceso de la póliza.');
        }
    }

    async obtenerTodosParentescosBeneficiario() {
        try {
            var registros = this.apiUrls.parentescosBeneficiario;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los parentescos.');
        }
    }

    async obtenerTodosParentescosDependiente() {
        try {
            var registros = this.apiUrls.parentescosDependiente;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los parentescos.');
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorPolizas(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * * * Certificados * * * * *
    async obtenerCertificadoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.certificadoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCertificado(registro: Certificado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Duplicar
    public async duplicarCertificado(CertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/duplicar';
            const res = await this.connection.postRequest(url, { CertificadoId: CertificadoId });
            return { error: null, data: { mensaje: 'Certificado duplicado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Duplicar póliza
    public async duplicarPoliza(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/duplicar';
            const res = await this.connection.postRequest(url, { PolizaOriginalId: PolizaId });
            return { error: null, data: { mensaje: 'Póliza duplicada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarCertificado(registro: Certificado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCertificado(CertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/id/' + CertificadoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Certificado eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async eliminarCertificadosMultiples(ids: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/eliminarMultiples';
            let data = { ids: ids };
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Certificados eliminados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async duplicarCertificadosMultiples(ids: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/duplicarMultiples';
            let data = { ids: ids };
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Certificados duplicados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodosTiposCertificadosRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposCertificadoURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.tipoCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de certificado.');
        }
    }

    // * * * * * Pagadores * * * * *
    async obtenerTodosPagadoresPoliza(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/pagadores/' + PolizaId;
            var json = await this.connection.getRequest(url);

            let registros = [];
            for (let i = 0; i < json.pagadores.length; i++) {
                const element = json.pagadores[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }

            let pagadoresPoliza = [];
            for (let i = 0; i < json.pagadoresPoliza.length; i++) {
                const element = json.pagadoresPoliza[i];
                pagadoresPoliza.push(this.jsonConverters.pagadorPolizaDeJSON(element));
            }

            let pagadoresCertificados = [];
            for (let i = 0; i < json.pagadoresCertificados.length; i++) {
                const element = json.pagadoresCertificados[i];
                pagadoresCertificados.push(this.jsonConverters.pagadorCertificadoDeJSON(element));
            }

            return { error: null, data: { registros: registros, pagadoresPoliza: pagadoresPoliza, pagadoresCertificados: pagadoresCertificados } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los pagadores de la póliza.');
        }
    }

    async obtenerTodosPagadoresPolizas(idsPolizas: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/pagadoresVarias/' + idsPolizas.toString();
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.pagadores.length; i++) {
                const element = json.pagadores[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los pagadores de la póliza.');
        }
    }

    async obtenerTodosCertificadosPoliza(PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/certificados/' + PolizaId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.certificados.length; i++) {
                const element = json.certificados[i];
                registros.push(this.jsonConverters.certificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los certificados de la póliza.');
        }
    }

    async obtenerTodosCertificadosPagadorPoliza(PolizaId: number, PagadorId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/certificadosPagadorPoliza?&PolizaId=' + PolizaId + '&PagadorId=' + PagadorId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.pagadores.length; i++) {
                const element = json.pagadores[i];
                registros.push(this.jsonConverters.pagadorCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los certificados de la póliza.');
        }
    }

    async obtenerTodosBeneficiariosCertificado(CertificadoId: number, tipos: string[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/beneficiarios/' + CertificadoId;
            if(tipos && tipos.length > 0) url += '?&tipos=' + tipos.toString();
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.beneficiarioDependienteCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los dependientes del certificado.');
        }
    }

    async obtenerTodosIncisosCertificado(CertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/incisos/' + CertificadoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.incisoCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los incisos del certificado.');
        }
    }

    async obtenerTodosDeduciblesCertificado(CertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/deducibles/' + CertificadoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.deducibleCertificadoDeJSON(element));
            }

            var registrosMoneda = [];
            for (let i = 0; i < json.deduciblesMoneda.length; i++) {
                const element = json.deduciblesMoneda[i];
                registrosMoneda.push(this.jsonConverters.deducibleMonedaCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros, deduciblesMoneda: registrosMoneda } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los deducibles del certificado.');
        }
    }

    async obtenerTodosCoberturasCertificado(CertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/coberturas/' + CertificadoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.coberturaIncisoCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las coberturas del certificado.');
        }
    }

    // * * * * * Declaración * * * * *
    async obtenerDeclaracionPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.declaracionesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.declaracionDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDeclaracion(registro: Declaracion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.declaracionesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarDeclaracion(registro: Declaracion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.declaracionesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDeclaracion(DeclaracionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.declaracionesURL + '/id/' + DeclaracionId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Declaración mensual eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Solicitudes * * *
    public async crearSolicitudPoliza(PolizaId: number, solicitud: SolicitudPoliza) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/solicitud/' + PolizaId;
            const res = await this.connection.postRequest(url, solicitud);
            return { error: null, data: { mensaje: 'Solicitud creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarSolicitudPoliza(solicitud: SolicitudPoliza) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/solicitud';
            const res = await this.connection.putRequest(url, solicitud);
            return { error: null, data: { mensaje: 'Solicitud actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async enviarSolicitudPoliza(envio: EnvioSolicitud) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/enviar';
            const res = await this.connection.postRequest(url, envio);
            return { error: null, data: { mensaje: 'Solicitud enviada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async guardarPuntuacionSolicitud(puntuacion: number, SolicitudId: number) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/puntuacion';
            const res = await this.connection.postRequest(url, {
                puntuacion: puntuacion, SolicitudId: SolicitudId,
            });
            return { error: null, data: { mensaje: 'Solicitud actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async generarMapaSolicitud(SolicitudPolizaId, PolizaId, PlantillaMapaProcesoId, UsuarioId) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/generarMapa';
            const res = await this.connection.postRequest(url, {
                SolicitudPolizaId: SolicitudPolizaId,
                PolizaId: PolizaId,
                PlantillaMapaProcesoId: PlantillaMapaProcesoId,
                UsuarioId: UsuarioId
            });
            return { error: null, data: { mensaje: 'Mapa de proceso generado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarSolicitudPoliza(SolicitudPolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/id/' + SolicitudPolizaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Solicitud eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async marcarComoEnviadaSolicitud(SolicitudId: number) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/marcarEnviada';
            const res = await this.connection.postRequest(url, {
                SolicitudId: SolicitudId,
            });
            return { error: null, data: { mensaje: 'Solicitud actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarPdfSolicitud(SolicitudPolizaId: number, nombreFinal: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/descargarSolicitud?SolicitudPolizaId=' + SolicitudPolizaId;
            var res = await this.connection.getDownloadRequest(url);
            if(res.type == 'application/json') {
                return {
                    error: null,
                    data: {
                        mensaje: 'Debido al tamaño de la solicitud, esta se generará en segundo plano y se le enviará un correo con el link de descarga en unos minutos.',
                        descargaInmediata: false,
                    }
                }
            } else {
                // Descargar archivo
                var fileName = nombreFinal + '.pdf';
                var mediaType = 'application/pdf';
                var blob = new Blob([res], {
                    type: mediaType
                });
                FileSaver.saveAs(blob, fileName);
    
                return {
                    error: null,
                    data: {
                        mensaje: 'Solicitud descargada con éxito.',
                        descargaInmediata: true,
                    }
                };
            }
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async visualizarPolizaPDF(SolicitudPolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/descargarSolicitud?SolicitudPolizaId=' + SolicitudPolizaId;
            var res = await this.connection.getDownloadRequest(url);

            if(res.type == 'application/json') {
                return {
                    error: null,
                    data: {
                        mensaje: 'Debido al tamaño de la solicitud, esta se generará en segundo plano y se le enviará un correo con el link de descarga en unos minutos.',
                        descargaInmediata: false,
                    }
                }
            } else {
                // Descargar archivo
                var mediaType = 'application/pdf';
                var blob = new Blob([res], {
                    type: mediaType
                });
    
                return {
                    error: null,
                    data: {
                        blob: blob,
                        descargaInmediata: true,
                    }
                };
            }
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Documentos * * * * *
    async guardarDocumentoPoliza(documento: DocumentoPoliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosPolizasURL;
            const res = await this.connection.postRequest(url, documento);
            return { error: null, data: { mensaje: 'Documento guardado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async editarDocumentoPoliza(documento: DocumentoPoliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosPolizasURL;
            const res = await this.connection.putRequest(url, documento);
            return { error: null, data: { mensaje: 'Documento editado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async eliminarDocumento(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosPolizasURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Documento eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Solicitudes * * *
    public async descargarPDFSolicitudPolizaCreada(SolicitudId) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/descargarSolicitud?SolicitudId=' + SolicitudId;
            const res = await this.connection.getRequest(url);
            if (!res.error && res.htmlString != null && res.solicitud) {
                var htmlString = res.htmlString;
                var response = await this.connection.downloadPDFFromHTML(htmlString);
                var solicitud = this.jsonConverters.solicitudPolizaDeJSON(res.solicitud);
                // Guardar archivo PDF
                var fileName = 'Solicitud de ' + solicitud.plantilla.nombre + ' de póliza #' + solicitud.PolizaId + '.pdf';
                var mediaType = 'application/pdf';
                var blob = new Blob([response], {
                    type: mediaType
                });
                FileSaver.saveAs(blob, fileName);
            }
            return {
                error: null,
                data: {
                    mensaje: 'Solicitud descargada con éxito',
                    result: res
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de datos pólizas * * * * *
    async lecturaImportacionPolizas(archivo, agentes: Usuario[], ejecutivos: Usuario[], clientes: Usuario[], aseguradoras: Aseguradora[], agrupadores: Agrupador[], monedas: Moneda[], ramos: Ramo[], productos: Producto[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. poliza', 'estatus', 'caracter', 'situacion actual', 'vigencia inicial', 'vigencia final',
                'cliente', 'ramo', 'producto', 'aseguradora', 'moneda', 'prima neta anualizada',
                'prima neta facturada', 'prima neta pagada', 'suma asegurada', 'agente', 'ejecutivo',
                'tipo', 'forma de cobro'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new Poliza(
                    null, trim(element['no. poliza']), null, null, null, trim(element['tipo']), false, null, null, null, null,
                    null, null, null, null, null, true, null, false, false, null,
                    null, null, null, 'corregida', false, false, false, null, null, null, null, null, null, null,
                    null, null, null, null, null, null, null, null, null, null, EmpresaId, null, null, null, null, null
                );
                // Números
                if(element['suma asegurada']) elementoFinal.sumaAsegurada = parseFloat(element['suma asegurada']);
                if(element['prima neta facturada']) elementoFinal.primaNeta = parseFloat(element['prima neta facturada']);

                // Vigencia
                var fechaVigenciaInicioOriginal = trim(element['vigencia inicial']);
                var fechaVigenciaInicio = null;
                if(fechaVigenciaInicioOriginal) fechaVigenciaInicio = this.libraryService.convertirFecha(fechaVigenciaInicioOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaVigenciaInicio = fechaVigenciaInicio;
                var fechaVigenciaFinOriginal = trim(element['vigencia final']);
                var fechaVigenciaFin = null;
                if(fechaVigenciaFinOriginal) fechaVigenciaFin = this.libraryService.convertirFecha(fechaVigenciaFinOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaVigenciaFin = fechaVigenciaFin;

                // Carácter
                var indexCaracter = this.libraryService.indexOf(this.apiUrls.caracteresPolizas, 'nombre', trim(element['caracter']));
                if(indexCaracter != -1) elementoFinal.caracter = this.apiUrls.caracteresPolizas[indexCaracter].id;
                else if(element['caracter']) elementoFinal.caracter = this.libraryService.normalizarString(element['caracter']);

                // Forma de cobro
                var indexFormaPago = this.libraryService.indexOf(this.apiUrls.formasCobro, 'nombre', trim(element['forma de cobro']));
                if(indexFormaPago != -1) elementoFinal.formaCobro = this.apiUrls.formasCobro[indexFormaPago].id;
                else if(element['forma de cobro']) elementoFinal.formaCobro = this.libraryService.normalizarString(element['forma de cobro']);

                // Estado
                var indexEstatus = this.libraryService.indexOf(this.apiUrls.estadosPolizas, 'nombre', trim(element['estatus']));
                if(indexEstatus != -1) elementoFinal.estado = this.apiUrls.estadosPolizas[indexEstatus].id;
                else if(element['estatus']) elementoFinal.estado = this.libraryService.normalizarString(element['estatus']);

                // Tipo
                var indexTipo = this.libraryService.indexOf(this.apiUrls.tiposPolizas, 'nombre', trim(element['tipo']));
                if(indexTipo != -1) elementoFinal.tipo = this.apiUrls.tiposPolizas[indexTipo].id;
                else if(element['tipo']) elementoFinal.tipo = this.libraryService.normalizarString(element['tipo']);

                // Aseguradora
                if(aseguradoras && element['aseguradora']) {
                    var indexAseguradora = this.libraryService.indexOf(aseguradoras, 'nombre', element['aseguradora']);
                    if(indexAseguradora != -1) {
                        elementoFinal.AseguradoraId = aseguradoras[indexAseguradora].id;
                    }
                }

                // Agrupador
                if(agrupadores && element['agrupador']) {
                    var indexAgrupador = this.libraryService.indexOf(agrupadores, 'nombre', element['agrupador']);
                    if(indexAgrupador != -1) elementoFinal.AgrupadorId = agrupadores[indexAgrupador].id;
                }

                // Moneda
                if(monedas && element['moneda']) {
                    var indexMoneda = this.libraryService.indexOf(monedas, 'simbolo', element['moneda']);
                    if(indexMoneda != -1) elementoFinal.MonedaId = monedas[indexMoneda].id;
                }

                // Ramo
                if(ramos && element['ramo']) {
                    var indexRamo = this.libraryService.indexOf(ramos, 'nombre', element['ramo']);
                    if(indexRamo != -1) elementoFinal.RamoId = ramos[indexRamo].id;
                }

                // Producto
                if(productos && element['producto']) {
                    var indexProducto = this.libraryService.indexOf(productos, 'nombre', element['producto']);
                    if(indexProducto != -1) {
                        elementoFinal.ProductoId = productos[indexProducto].id;
                        if(elementoFinal.RamoId == null) elementoFinal.RamoId = productos[indexProducto].RamoId;
                    }
                }

                // Agente
                if(agentes && element['agente']) {
                    var indexAgente = this.libraryService.indexOf(agentes, 'nombreCompleto', element['agente']);
                    if(indexAgente != -1) {
                        elementoFinal.AgenteId = agentes[indexAgente].id;
                        elementoFinal.VendedorId = agentes[indexAgente].id;
                    }
                }

                // Ejecutivo
                if(ejecutivos && element['ejecutivo']) {
                    var indexEjecutivo = this.libraryService.indexOf(ejecutivos, 'nombreCompleto', element['ejecutivo']);
                    if(indexEjecutivo != -1) {
                        elementoFinal.EjecutivoId = ejecutivos[indexEjecutivo].id;
                        elementoFinal.UsuarioId = ejecutivos[indexEjecutivo].id;
                    }
                }

                // Cliente
                if(clientes && element['cliente']) {
                    var indexCliente = this.libraryService.indexOf(clientes, 'nombreCompleto', element['cliente']);
                    if(indexCliente != -1) {
                        elementoFinal.ClienteId = clientes[indexCliente].id;
                        elementoFinal.cliente = clientes[indexCliente];
                    }
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionPolizas(registros: Poliza[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/analisisImportacionPolizas';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionPolizas(registros: Poliza[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/subirImportacionPolizas';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de beneficiarios o dependientes de certificados * * * * *
    async lecturaImportacionCertificadosBeneficiariosDependientes(archivo, modo: string, poliza: Poliza, PolizaId: number, certificados: Certificado[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. certificado', 'identificador', 'tipo', 'nombre', 'genero', 'nacimiento',
                'fecha de alta', 'parentesco', '% de beneficio', 'estatus', 'fecha de baja'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];

            var modoFinal = modo == 'beneficiarios' ? 'beneficiario' : 'dependiente';

            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new BeneficiarioDependienteCertificado(
                    null, trim(element['nombre']), modoFinal, trim(element['genero']),
                    trim(element['parentesco']), null, null, null, null, null, null, null, null,
                    null, null, null
                );

                // Certificado
                var indexCertificado = this.libraryService.indexOf(certificados, 'numero', trim(element['no. certificado']));
                if(indexCertificado != -1) elementoFinal.CertificadoId = certificados[indexCertificado].id;

                // Numéricos
                if(element['% de beneficio']) elementoFinal.porcentajeBeneficio = parseFloat(element['% de beneficio']);

                // Género
                if(element['genero']) elementoFinal.genero = element['genero'].toLowerCase();

                // Parentesco
                var indexParentesco = this.libraryService.indexOf(this.apiUrls.parentescosBeneficiario, 'nombre', trim(element['parentesco']));
                if(indexParentesco != -1) elementoFinal.parentesco = this.apiUrls.parentescosBeneficiario[indexParentesco].id;
                else if(element['parentesco']) elementoFinal.parentesco = this.libraryService.normalizarString(element['parentesco']);

                // Nacimiento
                var fechaNacimientoOriginal = trim(element['nacimiento']);
                var fechaNacimiento = null;
                if(fechaNacimientoOriginal) fechaNacimiento = this.libraryService.convertirFecha(fechaNacimientoOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaNacimiento = fechaNacimiento;

                // Alta
                var fechaAltaOriginal = trim(element['fecha de alta']);
                var fechaAlta = null;
                if(fechaAltaOriginal) fechaAlta = this.libraryService.convertirFecha(fechaAltaOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaAlta = fechaAlta;

                // Baja
                var fechaBajaOriginal = trim(element['fecha de alta']);
                var fechaBaja = null;
                if(fechaBajaOriginal) fechaBaja = this.libraryService.convertirFecha(fechaBajaOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaBaja = fechaBaja;

                // Estado
                var indexEstatus = this.libraryService.indexOf(this.apiUrls.estadosCertificados, 'nombre', trim(element['estatus']));
                if(indexEstatus != -1) elementoFinal.estado = this.apiUrls.estadosCertificados[indexEstatus].id;
                else if(element['estatus']) elementoFinal.estado = this.libraryService.normalizarString(element['estatus']);

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionCertificadosBeneficiariosDependientes(registros: BeneficiarioDependienteCertificado[], PolizaId: number, EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/analisisImportacionCertificadosBeneficiariosDependientes';
            var data = {
                registros: registros,
                PolizaId: PolizaId,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionCertificadosBeneficiariosDependientes(registros: BeneficiarioDependienteCertificado[], modo: string, PolizaId: number, EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionCertificadosBeneficiariosDependientes';
            var modoFinal = modo == 'beneficiarios' ? 'beneficiario' : 'dependiente';
            var data = {
                registros: registros,
                tipo: modoFinal,
                PolizaId: PolizaId,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            console.error(error);
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    esPersonas(poliza: Poliza) {
        if(poliza && poliza.ramo && poliza.ramo.area) {
            return poliza.ramo.area.nombre == 'Personas';
        }
        else return false;
    }

    esconderPrimasDiferenciadas(poliza: Poliza) {
        if(poliza && poliza.ramo) {
            return poliza.ramo.esconderPrimasDiferenciadasDependientes;
        }
        else return false;
    }

    esGastosMedicos(poliza: Poliza) {
        if(poliza && poliza.ramo) {
            return poliza.ramo.gastosMedicos;
        }
        else return false;
    }

    esVida(poliza: Poliza) {
        if(poliza && poliza.ramo) {
            return poliza.ramo.nombre.toUpperCase().includes('VIDA');
        }
        else return false;
    }

    esVehiculos(poliza: Poliza) {
        if(poliza && poliza.ramo && poliza.ramo.area) {
            return poliza.ramo.area.nombre == 'Vehículos';
        }
        else return false;
    }

    esBienes(poliza: Poliza) {
        if(poliza && poliza.ramo && poliza.ramo.area) {
            return poliza.ramo.area.nombre == 'Bienes';
        }
        else return false;
    }

    esFianzas(poliza: Poliza) {
        if(poliza && poliza.ramo && poliza.ramo.area) {
            return poliza.ramo.area.nombre == 'Fianzas';
        }
        else return false;
    }

    // * * * * * Importación de certificados com campos y tipos * * * * *
    async lecturaImportacionCertificadosTipoCampos(archivo, poliza: Poliza, PolizaId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. certificado', 'nombre', 'fecha de alta', 'estatus', 'vigencia inicial', 'vigencia final',
                'suma asegurada', 'prima neta facturada', 'prima neta anualizada'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];

            var tipoCamposGenerados = false;
            var tipoCertificado: TipoCertificado = null;

            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new Certificado(
                    null, trim(element['nombre']), null, null, null, null, null, null, null,
                    null, null, null, null, null, null, null, null, null, null,
                    element['no. certificado'],null,null,null,null, null, null, PolizaId, null, null, EmpresaId,
                    null, null, null, null,
                    null, null, null, null, null, null
                );
                // Numéricos
                if(element['suma asegurada']) elementoFinal.sumaAsegurada = parseFloat(element['suma asegurada']);
                if(element['prima neta facturada']) elementoFinal.primaFacturada = parseFloat(element['prima neta facturada']);
                if(element['prima neta anualizada']) elementoFinal.primaAnualizada = parseFloat(element['prima neta anualizada']);
                if(element['tasa']) elementoFinal.porcentajeTasa = parseFloat(element['tasa']);
                // Fix nombre
                if(!elementoFinal.nombre) {
                    elementoFinal.nombre = elementoFinal.numero;
                }
                // Alta
                var fechaAltaOriginal = trim(element['fecha de alta']);
                var fechaAlta = null;
                if(fechaAltaOriginal) fechaAlta = this.libraryService.convertirFecha(fechaAltaOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaAlta = fechaAlta;
                // Vigencia
                var fechaVigenciaInicioOriginal = trim(element['vigencia inicial']);
                var fechaVigenciaInicio = null;
                if(fechaVigenciaInicioOriginal) fechaVigenciaInicio = this.libraryService.convertirFecha(fechaVigenciaInicioOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaVigenciaInicio = fechaVigenciaInicio;
                var fechaVigenciaFinOriginal = trim(element['vigencia final']);
                var fechaVigenciaFin = null;
                if(fechaVigenciaFinOriginal) fechaVigenciaFin = this.libraryService.convertirFecha(fechaVigenciaFinOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaVigenciaFin = fechaVigenciaFin;

                // Estado
                var indexEstatus = this.libraryService.indexOf(this.apiUrls.estadosCertificados, 'abreviatura', trim(element['estatus']));
                if(indexEstatus != -1) elementoFinal.estado = this.apiUrls.estadosCertificados[indexEstatus].id;
                else if(element['estatus']) elementoFinal.estado = this.libraryService.normalizarString(element['estatus']);

                if(!tipoCamposGenerados) {
                    // Tipo de certificado
                    tipoCertificado = new TipoCertificado(null, 'Póliza # ' + poliza.numero, null, poliza.RamoId, EmpresaId);
                    tipoCertificado.campos = [];

                    // Campos
                    for (const key in element) {
                        if (Object.prototype.hasOwnProperty.call(element, key)) {
                            // Saltarse los campos del certificado
                            if(columnasUtilizadas.indexOf(key) == -1) {
                                var nombreCampo = key.toString().toUpperCase();
                                var nuevoCampo = new CampoTipoCertificado(null, nombreCampo, false, true, 'texto', tipoCertificado.campos.length + 1, null, null, EmpresaId);
                                tipoCertificado.campos.push(nuevoCampo);
                            }
                        }
                    }

                    tipoCamposGenerados = true;
                }

                elementoFinal.campos = JSON.parse(JSON.stringify(tipoCertificado.campos));
                for (let i = 0; i < elementoFinal.campos.length; i++) {
                    var campo = elementoFinal.campos[i];
                    var nuevoValor = new ValorCertificado(null, null, null, null, null, campo.id);
                    switch(campo.tipo) {
                        case 'texto': nuevoValor.texto = element[this.libraryService.normalizarString(campo.nombre)]; break;
                        case 'fecha': nuevoValor.fecha = element[this.libraryService.normalizarString(campo.nombre)]; break;
                        default: nuevoValor.valor = element[this.libraryService.normalizarString(campo.nombre)]; break;
                    }
                    elementoFinal.campos[i].valor = nuevoValor;
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                tipoCertificado: tipoCertificado,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionCertificadosTipoCampos(registros: Certificado[], PolizaId: number, tipoCertificado: TipoCertificado, EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/analisisImportacionCertificadosTipoCampos';
            var data = {
                registros: registros,
                PolizaId: PolizaId,
                tipoCertificado: tipoCertificado,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionCertificadosTipoCampos(registros: Certificado[], PolizaId: number, tipoCertificado: TipoCertificado, EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionCertificadosTipoCampos';
            var data = {
                registros: registros,
                PolizaId: PolizaId,
                tipoCertificado: tipoCertificado,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            console.error(error);
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de certificados por plantilla * * * * *
    async lecturaImportacionCertificadosPlantilla(archivo, tipoCertificado: TipoCertificado, plantillasRamos: PlantillaRamo[], PolizaId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. certificado', 'inciso', 'fecha de vigencia inicio', 'fecha de vigencia fin', 'fecha de alta', 'fecha de reporte de alta', 'estatus',
                'nombre de plantilla', 'suma asegurada', 'tarifa', 'tasa', 'prima neta',
                'id pagador 1', 'porcentaje pagador 1', 'id pagador 2', 'porcentaje pagador 2',
                'ur_pais', 'ur_departamento', 'ur_municipio', 'ur_zona', 'ur_complemento'

            ];
            // Agregar columnas de tipo de certificado
            if(tipoCertificado && tipoCertificado.campos) {
                for (var campo of tipoCertificado.campos) {
                    columnasUtilizadas.push(this.libraryService.normalizarString(campo.nombre));
                }
            }

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new Certificado(
                    null, null, null, null, null, null, null, null, null,
                    this.libraryService.extraerNumero(element['suma asegurada']),
                    this.libraryService.extraerNumero(element['prima neta']),
                    null, null, null, null, null, null, null, null,
                    element['no. certificado'], null, null, null, null, null, null,
                    PolizaId, tipoCertificado.id, null, EmpresaId,
                    null, null, null, null,
                    null, null, null, null, null, null
                );
                // Tarifa para inciso
                elementoFinal.tarifa = this.libraryService.extraerNumero(element['tarifa']);
                elementoFinal.porcentajeTasa = this.libraryService.extraerNumero(element['tasa']);
                // Inciso
                elementoFinal.nombreInciso = element['inciso'];
                // Pagadores
                elementoFinal.idPagador1 = this.libraryService.extraerNumero(element['id pagador 1']);
                elementoFinal.idPagador2 = this.libraryService.extraerNumero(element['id pagador 2']);
                elementoFinal.porcentajePagador1 = this.libraryService.extraerNumero(element['porcentaje pagador 1']);
                elementoFinal.porcentajePagador2 = this.libraryService.extraerNumero(element['porcentaje pagador 2']);
                // Alta
                var fechaAltaOriginal = trim(element['fecha de alta']);
                var fechaAlta = null;
                if(fechaAltaOriginal) fechaAlta = this.libraryService.convertirFecha(fechaAltaOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaAlta = fechaAlta;
                // Reporte alta
                var fechaReporteAltaOriginal = trim(element['fecha de reporte de alta']);
                var fechaReporteAlta = null;
                if(fechaReporteAltaOriginal) fechaReporteAlta = this.libraryService.convertirFecha(fechaReporteAltaOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaReporteAlta = fechaReporteAlta;
                // Vigencia
                var fechaVigenciaInicioOriginal = trim(element['fecha de vigencia inicio']);
                var fechaVigenciaInicio = null;
                if(fechaVigenciaInicioOriginal) fechaVigenciaInicio = this.libraryService.convertirFecha(fechaVigenciaInicioOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaVigenciaInicio = fechaVigenciaInicio;
                var fechaVigenciaFinOriginal = trim(element['fecha de vigencia fin']);
                var fechaVigenciaFin = null;
                if(fechaVigenciaFinOriginal) fechaVigenciaFin = this.libraryService.convertirFecha(fechaVigenciaFinOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaVigenciaFin = fechaVigenciaFin;

                // Estado
                var indexEstatus = this.libraryService.indexOf(this.apiUrls.estadosCertificados, 'abreviatura', trim(element['estatus']));
                if(indexEstatus != -1) elementoFinal.estado = this.apiUrls.estadosCertificados[indexEstatus].id;
                else if(element['estatus']) elementoFinal.estado = this.libraryService.normalizarString(element['estatus']);

                // Plantilla
                if(plantillasRamos && element['nombre de plantilla']) {
                    let plantillaUbicada = plantillasRamos.find(plantilla => plantilla.nombre.toLowerCase().trim() == element['nombre de plantilla'].toLowerCase().trim());
                    if(plantillaUbicada) {
                        elementoFinal.PlantillaRamoId = plantillaUbicada.id;
                    }
                }

                // Campos
                elementoFinal.campos = JSON.parse(JSON.stringify(tipoCertificado.campos));
                let nombreFinal = '';
                for (let i = 0; i < elementoFinal.campos.length; i++) {
                    var campo = elementoFinal.campos[i];
                    var nuevoValor = new ValorCertificado(null, null, null, null, null, campo.id);
                    switch(campo.tipo) {
                        case 'texto': {
                            nuevoValor.texto = element[this.libraryService.normalizarString(campo.nombre)];
                            if(nombreFinal != '') nombreFinal += ', ';
                            nombreFinal += nuevoValor.texto;
                            break;
                        }
                        case 'fecha': nuevoValor.fecha = element[this.libraryService.normalizarString(campo.nombre)]; break;
                        default: nuevoValor.valor = element[this.libraryService.normalizarString(campo.nombre)]; break;
                    }
                    elementoFinal.campos[i].valor = nuevoValor;
                }
                elementoFinal.nombre = nombreFinal;
                elementoFinal.nombreIncisoAux = element['inciso'];

                // Ubicación de riesgo
                if(element['ur_pais']) {
                    elementoFinal.auxDireccion = new DireccionCertificado(null, 'principal', element['ur_complemento'], null, null, null, null, null, null, null);
                    elementoFinal.auxDireccion.nombrePais = element['ur_pais'];
                    elementoFinal.auxDireccion.nombreDepartamento = element['ur_departamento'];
                    elementoFinal.auxDireccion.nombreMunicipio = element['ur_municipio'];
                    elementoFinal.auxDireccion.nombreZona = element['ur_zona'];
                }

                resultadosFinales.push(elementoFinal);
            });

            // Validar duplicidad dentro del mismo archivo
            for (const el of resultadosFinales) {
                if(el.campos) {
                    for (const elCampo of el.campos) {
                        if(elCampo.nombre) {
                            if(['PLACA', 'CHASIS'].includes(elCampo.nombre.trim().toUpperCase())) {
                                // Validar con otras placas o chasises
                                let duplicado = resultadosFinales.find(x => x.nombre != el.nombre && x.campos.find(y => y.nombre == elCampo.nombre && y.valor && y.valor.texto == elCampo.valor.texto));
                                if(duplicado) {
                                    errores.push({ mensaje: `El campo ${elCampo.nombre} con valor ${elCampo.valor.texto} está duplicado en el archivo.` });
                                }
                            }
                        }
                    }
                }
            }

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionCertificadosPlantilla(registros: Certificado[], PolizaId: number, TipoCertificadoId: number, EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/analisisImportacionCertificadosPlantilla';
            var data = {
                registros: registros,
                PolizaId: PolizaId,
                TipoCertificadoId: TipoCertificadoId,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionCertificadosPlantilla(registros: Certificado[], PolizaId: number, TipoCertificadoId: number, EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionCertificadosPlantilla';
            var data = {
                registros: registros,
                PolizaId: PolizaId,
                TipoCertificadoId: TipoCertificadoId,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaCertificados(nombreArchivo: string, TipoCertificadoId: number, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcel/' + TipoCertificadoId;
            url += '?&PolizaId=' + PolizaId;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionClausulasPlantilla(archivo, PolizaId: number, EmpresaId: number) {
      try {
          // Obtener resultados del CSV
          var resArchivo = await Papa.parsePromise(archivo);
          if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

          // Sanitizar objetos en nombres de columnas
          var columnasUtilizadas = [
              'beneficiario', 'propietario', 'valor de la clausula', 'descripcion'
          ];

          var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
          if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
          var errores = resNormalizacion.errores;
          var resultados = resNormalizacion.resultados;

          // Preparar resultados según modelo
          var trim = this.libraryService.trimString;
          var resultadosFinales = [];
          resultados.forEach(element => {
              // Póliza
              var elementoFinal = new ClausulaGarantiaPoliza(
                -1, this.auth.usuario.nombreCompleto, element['beneficiario'],
                element['descripcion'], element['propietario'], element['valor de la clausula'],
                moment().format('YYYY-MM-DD'), null, null, null, PolizaId, null, this.auth.idUsuarioActual(), EmpresaId, null
              );

              resultadosFinales.push(elementoFinal);
          });

          return {
              error: false,
              errores: errores,
              resultados: resultadosFinales,
              mensaje: 'Lectura de archivo realizada con éxito.',
          };
      } catch(error) {
          return { error: true, mensaje: error.message };
      }
  }

  async analizarImportacionClausulasPlantilla(registros: ClausulaGarantiaPoliza[], PolizaId: number, EmpresaId: number) {
      try {
          var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/analisisImportacionClausulasPlantilla';
          var data = {
              registros: registros,
              PolizaId: PolizaId,
              EmpresaId: EmpresaId,
          }
          const res = await this.connection.postRequest(url, data);
          var registrosNuevos = res.registrosNuevos;
          var registrosEditados = res.registrosEditados;
          var errores = res.errores;

          return { error: null, data: {
              mensaje: 'Importación analizada con éxito',
              registrosNuevos: registrosNuevos,
              registrosEditados: registrosEditados,
              errores: errores,
              result: res,
          } };
      } catch (error) {
          return this.connection.obtenerMensajeError(error.status, error.error.message);
      }
  }

  async subirImportacionClausulasPlantilla(registros: ClausulaGarantiaPoliza[], PolizaId: number, EmpresaId: number) {
      try {
          var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/subirImportacionClausulasPlantilla';
          var data = {
              registros: registros,
              PolizaId: PolizaId,
              EmpresaId: EmpresaId,
          }
          const res = await this.connection.postRequest(url, data);

          return { error: null, data: {
              mensaje: 'Importación subida con éxito',
              result: res,
          } };
      } catch (error) {
          return this.connection.obtenerMensajeError(error.status, error.error.message);
      }
  }

    async descargarExcelPlantillaClausula(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/plantilla-clausula';
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaBeneficiariosDependientes(nombreArchivo: string, tipo: string, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcelBeneficiariosDependientes/' + tipo;
            url += '?&PolizaId=' + PolizaId;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaUbicaciones(nombreArchivo: string, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcelUbicaciones/';
            url += '?&PolizaId=' + PolizaId;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaDeclaraciones(nombreArchivo: string, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcelDeclaraciones';
            url += '?&PolizaId=' + PolizaId;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaDetallesDeclaracion(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcelDetallesDeclaracion';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionDeclaracionesPlantilla(archivo, PolizaId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'certificado', 'fecha de declaracion', 'monto declarado', 'tasa', 'prima neta', 'endoso'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new Declaracion(
                    null, null, null, null, element['monto declarado'], element['tasa'],
                    element['prima neta'], null, element['endoso'], null, null, null, null, EmpresaId
                );
                elementoFinal.numeroCertificado = element['certificado']
                elementoFinal.fechaDeclaracion = this.libraryService.convertirFecha(element['fecha de declaracion'], 'DD/MM/YYYY', 'YYYY-MM-DD');

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async subirImportacionDeclaracionesPlantilla(registros: Declaracion[], EmpresaId: number, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionDeclaracionesPlantilla';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
                PolizaId: PolizaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * Exclusión masiva *
    async descargarExcelPlantillaExclusionMasiva(nombreArchivo: string, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcelExclusionMasiva';
            url += '?&PolizaId=' + PolizaId;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionExclusionMasivaPlantilla(archivo, PolizaId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'certificado', 'fecha de fin de vigencia', 'fecha de reporte de baja', 'fecha de baja'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal: any = {}
                elementoFinal.numeroCertificado = element['certificado']
                elementoFinal.fechaVigenciaFin = this.libraryService.convertirFecha(element['fecha de fin de vigencia'], 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaReporteBaja = this.libraryService.convertirFecha(element['fecha de reporte de baja'], 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaBaja = this.libraryService.convertirFecha(element['fecha de baja'], 'DD/MM/YYYY', 'YYYY-MM-DD');

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async subirImportacionExclusionMasivaPlantilla(registros: any[], EmpresaId: number, PolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionExclusionMasivaPlantilla';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
                PolizaId: PolizaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionBeneficiariosPlantilla(archivo, tipo: string, PolizaId: number, CertificadoId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'certificado', 'nombre', 'parentesco', 'estado', 'porcentaje', 'observaciones',
                'genero', 'fecha de nacimiento', 'fecha de alta', 'fecha de baja'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new BeneficiarioDependienteCertificado(
                    null, element['nombre'], tipo, null, null, null, null, null,
                    null, null, null, null, null, element['observaciones'],
                    null, null
                );
                elementoFinal.porcentajeBeneficio = this.libraryService.extraerNumero(element['porcentaje']);

                // Nacimiento
                var fechaNacimientoOriginal = trim(element['fecha de nacimiento']);
                var fechaNacimiento = null;
                if(fechaNacimientoOriginal) fechaNacimiento = this.libraryService.convertirFecha(fechaNacimientoOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaNacimiento = fechaNacimiento;
                // Alta
                var fechaAltaOriginal = trim(element['fecha de alta']);
                var fechaAlta = null;
                if(fechaAltaOriginal) fechaAlta = this.libraryService.convertirFecha(fechaAltaOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaAlta = fechaAlta;
                // Baja
                var fechaBajaOriginal = trim(element['fecha de baja']);
                var fechaBaja = null;
                if(fechaBajaOriginal) fechaBaja = this.libraryService.convertirFecha(fechaBajaOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD', true);
                elementoFinal.fechaBaja = fechaBaja;

                // Estado
                var indexEstado = this.libraryService.indexOf(this.apiUrls.estadosCertificados, 'abreviatura', trim(element['estado']));
                if(indexEstado != -1) elementoFinal.estado = this.apiUrls.estadosCertificados[indexEstado].id;
                else if(element['estado']) elementoFinal.estado = this.libraryService.normalizarString(element['estado']);

                // Genero
                switch(element['genero']) {
                    case 'M': elementoFinal.genero = 'masculino'; break;
                    case 'F': elementoFinal.genero = 'femenino'; break;
                    default: elementoFinal.genero = 'otro'; break;
                }

                // Parentesco
                let abreviaturaParentesco = element['parentesco'];
                let objParentesco = this.apiUrls.parentescosBeneficiario.find(x => x.abreviatura == abreviaturaParentesco);
                if(objParentesco) elementoFinal.parentesco = objParentesco.id;

                elementoFinal.numeroCertificado = element['certificado'].toString();

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async subirImportacionBeneficiariosPlantilla(registros: BeneficiarioDependienteCertificado[], CertificadoId: number, tipo: string, EmpresaId: number, PolizaId: number, aplicarAPoliza: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionBeneficiariosPlantilla';
            var data = {
                registros: registros,
                CertificadoId: CertificadoId,
                tipo: tipo,
                EmpresaId: EmpresaId,
                aplicarAPoliza: aplicarAPoliza,
                PolizaId: PolizaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionUbicacionesPlantilla(archivo, PolizaId: number, CertificadoId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'certificado', 'ur_pais', 'ur_departamento', 'ur_municipio', 'ur_zona', 'ur_complemento'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                let elementoFinal = new DireccionCertificado(null, 'principal', element['ur_complemento'], null, null, null, null, null, null, null);
                elementoFinal.nombrePais = element['ur_pais'];
                elementoFinal.nombreDepartamento = element['ur_departamento'];
                elementoFinal.nombreMunicipio = element['ur_municipio'];
                elementoFinal.nombreZona = element['ur_zona'];

                elementoFinal.numeroCertificado = element['certificado'].toString();

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async subirImportacionUbicacionesPlantilla(registros: DireccionCertificado[], CertificadoId: number, EmpresaId: number, PolizaId: number, aplicarAPoliza: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/subirImportacionUbicacionesPlantilla';
            var data = {
                registros: registros,
                CertificadoId: CertificadoId,
                EmpresaId: EmpresaId,
                aplicarAPoliza: aplicarAPoliza,
                PolizaId: PolizaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelFacturacionesPoliza(nombreArchivo: string, PolizaId: number, mes: number, anio: number, diaCorte: number, diaEfectivo: number, incluirGastosEmision: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/facturacionesExcel';
            url += '?&PolizaId=' + PolizaId;
            url += '&mes=' + mes;
            url += '&anio=' + anio;
            url += '&diaCorte=' + diaCorte;
            url += '&diaEfectivo=' + diaEfectivo;
            if(incluirGastosEmision) url += '&incluirGastosEmision=1';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Facturaciones descargadas con éxito.' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelDeclaracionesCertificado(nombreArchivo: string, CertificadoId: number, IncisoCertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/declaracionesExcel';
            url += '?&CertificadoId=' + CertificadoId;
            url += '&IncisoCertificadoId=' + IncisoCertificadoId;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Declaraciones descargadas con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerNumeroPolizaMes(mes, anio) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/obtenerNumeroPoliza';
            //var params = `?&mes=${mes}&anio=${anio}`
            //url+= params;
            const res = await this.connection.getRequest(url);

            return { error: null, data: {
                mensaje: 'Numero de poliza del mes',
                error: res.error,
                result: res.data,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async validarChasis(chasis, placa, motor, CertificadoId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/validarChasis';
            let data = {
                chasis: chasis,
                placa: placa,
                motor: motor,
                CertificadoId: CertificadoId,
            };
            const res = await this.connection.postRequest(url, { data: data });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async validarNombres(nombres, apellidos, CertificadoId, PolizaId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/validarNombres';
            let data = {
                nombres: nombres,
                apellidos: apellidos,
                CertificadoId: CertificadoId,
                PolizaId: PolizaId,
            };
            const res = await this.connection.postRequest(url, { data: data });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    resaltarResguardo(poliza: Poliza) {
        if(poliza.resguardo && moment(new Date()).isAfter(poliza.fechaVigenciaFin)) return true;
        else return false;
    }

    obtenerColorEstado(estado, poliza: Poliza) {
        if(poliza.resguardo) {
            if(this.resaltarResguardo(poliza)) return 'black';
            else return '#fc9803';
        }

        switch(estado) {
            case 'emision': return '#0b60b5';
            case 'nueva': return '#52b50b';
            case 'traslado': return '#853b07';
            case 'renovada': return '#000000';
            case 'historica': return '#878787';
            case 'cancelada': return '#d40000';
            default: return '#000000';
        }
    }

    obtenerColorEstadoCertificado(nombreEstado) {
        let index = this.libraryService.indexOf(this.apiUrls.estadosCertificados, 'nombre', nombreEstado);
        if(index != -1) {
            switch(this.apiUrls.estadosCertificados[index].id) {
                case 'activo': return 'green';
                case 'inactivo': return 'black';
                case 'incluido': return 'blue';
                case 'excluido': return 'red';
            }
        }
        else return 'black';
    }

    obtenerColorEstadoCertificadoConId(estado) {
        let index = this.libraryService.indexOf(this.apiUrls.estadosCertificados, 'id', estado);
        if(index != -1) {
            switch(this.apiUrls.estadosCertificados[index].id) {
                case 'activo': return 'green';
                case 'inactivo': return 'black';
                case 'incluido': return 'blue';
                case 'excluido': return 'red';
            }
        }
        else return 'black';
    }

    calcularColorEstadoCobro(nombreEstado) {
        let index = this.libraryService.indexOf(this.apiUrls.etapasCobros, 'nombre', nombreEstado);
        if(index != -1) {
            return this.apiUrls.etapasCobros[index].color;
        }
        else return 'black';
    }

    obtenerColorEstadoSolicitudPoliza(nombreEstado) {
        let index = this.libraryService.indexOf(this.apiUrls.estadosSolicitudesPolizas, 'nombre', nombreEstado);
        if(index != -1) {
            return this.apiUrls.estadosSolicitudesPolizas[index].color;
        }
        else return 'black';
    }

    async obtenerAnalyticsPolizas(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics.');
        }
    }

    async obtenerAnalyticsControlCalidad(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesPolizasURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics.');
        }
    }

    // * * * Aclaraciones * * *
    public async crearAclaracionPoliza(registro: AclaracionPoliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/aclaracion';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Aclaración creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarAclaracionPoliza(registro: AclaracionPoliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/aclaracion';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Aclaración actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarAclaracionPoliza(AclaracionPolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/aclaracion/' + AclaracionPolizaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Aclaración eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Cláusulas garantía * * *
    public async crearClausulaGarantiaPoliza(registro: ClausulaGarantiaPoliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/clausulaGarantia';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cláusula de garantía creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarClausulaGarantiaPoliza(registro: ClausulaGarantiaPoliza) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/clausulaGarantia';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Cláusula de garantía actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarClausulaGarantiaPoliza(ClausulaGarantiaPolizaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/clausulaGarantia/' + ClausulaGarantiaPolizaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cláusula de garantía eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelClausulaGarantiaPoliza(nombre: string, params: string) {
        try {
            // Construir ruta
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/descargarExcel';
            if(params) url+= '?'+ params;
            var response = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombre}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([ response ], { type: mediaType });
            FileSaver.saveAs(blob, filename);
            return response;
        } catch (error) {
            return null;
        }
    }

    public async cambiarEstadoClausulaGarantia(ids: number[], estado: string, fechaBaja: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/cambiarEstado';
            const res = await this.connection.postRequest(url, { ids: ids, estado: estado, fechaBaja: fechaBaja });
            return { error: null, data: { mensaje: 'Cláusulas de garantías actualizadas con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelCertificadosDePoliza(PolizaId: number, numeroPoliza: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/excelCertificadosPoliza';
            var data = { PolizaId: PolizaId }

            const res = await this.connection.postRequestDownload(url, data);

            // Descargar archivo
            var filename = `Certificados ${numeroPoliza}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelAnalytics(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/analyticsExcel';
            url += '?' + params;

            const res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `Pólizas.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionDetallesDeclaracion(archivo, EmpresaId: number, DeclaracionId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. embarque',
                'no. factura',
                'mercaderia',
                'via',
                'origen',
                'destino',
                'valor del embarque',
                'gastos adicionales',
                'total declarado',
                'tasa',
                'prima neta',
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                let detalleDeclaracion = new DetalleDeclaracion(
                    null,
                    element['no. embarque'],
                    null,
                    element['no. factura'],
                    element['mercaderia'],
                    element['via'],
                    element['origen'],
                    element['destino'],
                    element['valor del embarque'],
                    element['gastos adicionales'],
                    element['total declarado'],
                    element['tasa'],
                    element['prima neta'],
                    DeclaracionId,
                    EmpresaId
                );

                detalleDeclaracion.valorEmbarque = this.libraryService.stringMonedaANumero(element['valor del embarque']);
                detalleDeclaracion.gastosAdicionales = this.libraryService.stringMonedaANumero(element['gastos adicionales']);
                detalleDeclaracion.totalDeclarado = this.libraryService.stringMonedaANumero(element['total declarado']);
                detalleDeclaracion.tasa = this.libraryService.stringMonedaANumero(element['tasa']);
                detalleDeclaracion.primaNeta = this.libraryService.stringMonedaANumero(element['prima neta']);

                resultadosFinales.push(detalleDeclaracion);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    // * * * * * Direcciones certificados * * * * *
    async obtenerTodosDireccionesCertificados(CertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesCertificadosURL;
            if(CertificadoId) url += '?&CertificadoId=' + CertificadoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.direccionCertificadoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerDireccionCertificadoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesCertificadosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.direccionCertificadoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDireccionCertificado(registro: DireccionCertificado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesCertificadosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarDireccionCertificado(registro: DireccionCertificado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesCertificadosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDireccionCertificado(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesCertificadosURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}
